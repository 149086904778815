import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../component/appLayout';
import {
  BackIcon,
  Button,
  Container,
  Contentheading,
  DropdownButton,
  Empty,
  Header,
  HeaderLeft,
  ItemDiv,
  Leftcontent,
  TableSection
} from '../styles';
import { Bottomdata, Table } from '../styles/style';
import reportdarkempty from '../../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../../assets/images/emptystates/report-empty.svg';
import { isEmpty } from '../../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerList } from '../../../services/customerServices';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { CustomerDetailsInterface } from '../../../interfaces/CustomerInterface';
import moment from 'moment';
import { getProjectReportData } from '../../../services/reportService';
import { setFilteredProjectReportData, updateFilteredProjectReportData } from '../../../actions/reportActions';
import EmptyState from '../../../component/emptyState';
import { addInvitedMember, removeInvitedMember } from '../../../services/projectServices';
import { ProjectReportInterface } from '../../../interfaces/ReportInterface';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useHistory } from 'react-router-dom';
import { setErrorMessage } from '../../../actions/messageActions';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

const Projects: React.FC = () => {
  //States
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [selectAllCustomer, setSelectAllCustomer] = useState(false);
  const [customerList, setCustomerList] = useState<CustomerDetailsInterface[]>([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState<CustomerDetailsInterface[]>([]);
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    report: reportSelector,
    workspace: workspaceSelector,
    auth: authSelector
  } = stateSelector;
  const { themeMode } = settingsSelector;
  const { filteredProjectReportData, projectReportData, loading } = reportSelector;
  const { userDetails } = authSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const history = useHistory();
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  const dispatch = useDispatch();

  const applyFilter = useCallback(
    (data: ProjectReportInterface[], customers: CustomerDetailsInterface[], isCheckedAll = false) => {
      if (isCheckedAll) {
        dispatch(setFilteredProjectReportData(data));
      } else {
        let filteredData = JSON.parse(JSON.stringify(data));
        const filteredCustomers: string[] = customers?.filter((item) => item?.checked)?.map((x) => x?.id) || [];
        filteredData = filteredData.filter((item: ProjectReportInterface) =>
          filteredCustomers.includes(item?.customerId)
        );
        dispatch(setFilteredProjectReportData(filteredData));
      }
    },
    [dispatch]
  );

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        const result = await Promise.all([dispatch(getProjectReportData(workspace?.id)), dispatch(getCustomerList())]);
        let customerResponse = result[1];
        customerResponse = customerResponse?.map((item: CustomerDetailsInterface) => {
          return { ...item, checked: false };
        });
        setCustomerList(customerResponse);
        setFilteredCustomerList(customerResponse);
        applyFilter(result[0], customerResponse, true);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    }
  }, [applyFilter, dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickonClient = useCallback((value: boolean) => {
    setIsCustomerDropdownOpen(value);
  }, []);

  const onSelectAllCustomerClick = useCallback(
    (customerList: CustomerDetailsInterface[], value: boolean) => {
      setSelectAllCustomer(value);
      let customerListClone: CustomerDetailsInterface[] = JSON.parse(JSON.stringify(customerList || []));
      customerListClone = customerListClone?.map((item) => {
        return { ...item, checked: value };
      });
      setCustomerList(customerListClone);
      setFilteredCustomerList(customerListClone);
      applyFilter(projectReportData, customerListClone, !value);
    },
    [applyFilter, projectReportData]
  );

  const onChangeSearchCustomer = useCallback(
    (value: string) => {
      setSearchCustomer(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            customerList?.filter((item: CustomerDetailsInterface) =>
              item?.companyName?.toLowerCase().includes(value?.toLowerCase())
            ) || [];
          setFilteredCustomerList(newList);
        }, 300);
      } else {
        setFilteredCustomerList(customerList);
      }
    },
    [customerList]
  );

  const onCheckCustomer = useCallback(
    (
      allUserList: CustomerDetailsInterface[],
      filteredList: CustomerDetailsInterface[],
      userId: string,
      value: boolean
    ) => {
      const updatedAllList = allUserList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const uncheckedTasks = updatedFilteredList?.find((x) => !x?.checked);
      const isAllSelected = updatedAllList?.filter((x) => x?.checked);
      if (uncheckedTasks || updatedFilteredList?.length === 0) setSelectAllCustomer(false);
      else setSelectAllCustomer(true);
      setCustomerList(updatedAllList);
      setFilteredCustomerList(updatedFilteredList);
      applyFilter(projectReportData, updatedAllList, isAllSelected?.length === 0);
    },
    [applyFilter, projectReportData]
  );

  const renderCustomerData = useMemo(() => {
    return (
      <Bottomdata>
        {isEmpty(searchCustomer) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={selectAllCustomer}
            onClick={() => onSelectAllCustomerClick(filteredCustomerList, !selectAllCustomer)}
          />
        )}
        {(isEmpty(searchCustomer) ? customerList : filteredCustomerList)?.map((item) => {
          return (
            <DropdownItem
              key={item?.id}
              label={item?.companyName}
              isCheckBox={true}
              isChecked={item?.checked}
              onClick={() => onCheckCustomer(customerList, filteredCustomerList, item?.id, !item?.checked)}
            />
          );
        })}
      </Bottomdata>
    );
  }, [
    customerList,
    filteredCustomerList,
    onCheckCustomer,
    onSelectAllCustomerClick,
    searchCustomer,
    selectAllCustomer
  ]);

  const onClickJoinAction = useCallback(
    async (project: ProjectReportInterface) => {
      try {
        const updatedItem = { ...project, isUserExist: true };
        dispatch(updateFilteredProjectReportData(updatedItem));
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const payload = {
          userId: userDetails?.id,
          newUserId: userDetails?.id,
          companyId: workspace_id,
          projectId: project?.id
        };
        await dispatch(addInvitedMember(payload, true, true));
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [dispatch, workspace_id]
  );

  const onClickLeaveAction = useCallback(
    async (project: ProjectReportInterface) => {
      try {
        const updatedItem = { ...project, isUserExist: false };
        dispatch(updateFilteredProjectReportData(updatedItem));
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        await dispatch(removeInvitedMember(userDetails?.id, project?.id, userDetails?.id, true, true));
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (item: ProjectReportInterface) => {
      if (item?.isUserExist) {
        history.push(`/projects/details/${item?.id}/tasks`);
      } else {
        dispatch(setErrorMessage('You have to join this project first!'));
      }
    },
    [dispatch, history]
  );

  return (
    <>
      <AppLayout>
        <Header>
          <HeaderLeft>
            <BackIcon onClick={() => history.goBack()}>
              <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
            </BackIcon>
            Projects
          </HeaderLeft>
        </Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <Dropdown
                onChangeSearch={onChangeSearchCustomer}
                content={
                  <DropdownButton>
                    <div>
                      <Button onClick={() => onClickonClient(!isCustomerDropdownOpen)}>
                        <p>Client</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isCustomerDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsCustomerDropdownOpen(false)}>
                <ItemDiv>{renderCustomerData}</ItemDiv>
              </Dropdown>
            </Leftcontent>
          </Contentheading>
          {(filteredProjectReportData?.length === 0 || !filteredProjectReportData) && !loading && (
            <Empty>
              <EmptyState
                header={'There is no report data.'}
                title={'You have no report data.'}
                image={getImageSource()}
                name={''}
                hideButton={true}
              />
            </Empty>
          )}
          {filteredProjectReportData?.length > 0 && (
            <TableSection>
              <Table isproject>
                <thead>
                  <tr>
                    <th className='first-column'>Projects</th>
                    <th className='second-column'>Client Company</th>
                    <th className='third-column'>Started</th>
                    <th className='forth-column'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProjectReportData?.map((item) => {
                    return (
                      <tr key={item?.id}>
                        <td onClick={() => handleClick(item)}>{item?.projectName}</td>
                        <td>{item?.customerName}</td>
                        <td>{moment(item?.startedDate).format(userDetails?.dateFormat)}</td>
                        <td>
                          {item?.isUserExist ? (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickLeaveAction(item)}
                              className='leave-link'>
                              Leave
                            </div>
                          ) : (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => onClickJoinAction(item)}
                              className='join-link'>
                              Join
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableSection>
          )}
        </Container>
      </AppLayout>
    </>
  );
};

export default Projects;
