import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Headline = styled.h6`
  ${Typography.body_lg}
  color: var(--text-secondary);
  margin: 0;
`;

const Icon1 = styled.div`
  display: inline-flex;
  cursor: pointer;
  .closeicon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Input = styled.input`
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  min-width: 342px;
  :focus-visible {
    outline: none;
  }
`;

const InputDiv = styled.div`
  position: relative;
  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export { Headline, Icon1, Input, InputDiv };
