import React, { useCallback } from 'react';
import { UserModel } from '../../../mobx/models/user';
import {
  Check,
  Inputvalue2,
  Invite,
  Members,
  Modalblock,
  Modalhead,
  Name,
  Profile,
  Searchbar,
  Taskbutton
} from '../../../pages/project/details/setting/styles';
import Avatar from '../../avatar/Avatar';
import ModalCustom from '../modal';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Headline, Icon1, Input, InputDiv } from './style';

interface InviteMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectName: string;
  renderData: UserModel[];
  query: string;
  onChangeSearch: (e: { target: { value: string } }) => void;
  customersUsers?: UserModel[];
  isWorkspaceUser: boolean;
  onClickWorkspaceButton: (e: React.SyntheticEvent) => void;
  onClickClientButton: (e: React.SyntheticEvent) => void;
  currentProjectUsers?: UserModel[];
  onClickMemberBox: (userIndex: number, userItem: UserModel) => void;
  onSubModel: () => void;
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  isOpen,
  onClose,
  projectName,
  renderData,
  query,
  onChangeSearch,
  customersUsers,
  isWorkspaceUser,
  onClickWorkspaceButton,
  onClickClientButton,
  currentProjectUsers,
  onClickMemberBox,
  onSubModel
}) => {
  const renderMemberList = useCallback(
    (renderData: UserModel[]) => {
      return (
        <Modalblock>
          <Searchbar>
            <InputDiv>
              <Input placeholder='Search by name' className='search' value={query} onChange={onChangeSearch} />
              <SVGIcon name='search-icon' width='18' height='18' viewBox='0 0 16 16' className='icon' />
            </InputDiv>
            {(customersUsers?.length ?? 0) > 0 && (
              <div style={{ display: 'flex', gap: 8, paddingTop: 12 }}>
                <Taskbutton isActive={isWorkspaceUser} onClick={onClickWorkspaceButton}>
                  Workspace
                </Taskbutton>
                <Taskbutton isActive={!isWorkspaceUser} onClick={onClickClientButton}>
                  Client
                </Taskbutton>
              </div>
            )}
          </Searchbar>
          <Members>
            {renderData?.map((userItem) => {
              const userIndex = currentProjectUsers?.findIndex((user) => user?.id === userItem?.id) ?? -1;
              return (
                <Profile key={userItem?.id} onClick={() => onClickMemberBox(userIndex, userItem)}>
                  <Name>
                    <Avatar
                      imgSrc={userItem?.profile_photo || ''}
                      name={userItem?.name ? userItem.name : 'U N'}
                      size={28}
                      isNotBorder={true}
                    />
                    <p>{userItem?.name}</p>
                  </Name>
                  <Check>
                    <Inputvalue2 type='checkbox' className='checkbox-round' checked={userIndex === -1 ? false : true} />
                  </Check>
                </Profile>
              );
            })}
          </Members>
          <Invite onClick={onSubModel}>Invite New Member</Invite>
        </Modalblock>
      );
    },
    [
      query,
      onChangeSearch,
      customersUsers?.length,
      isWorkspaceUser,
      onClickWorkspaceButton,
      onClickClientButton,
      currentProjectUsers,
      onClickMemberBox,
      onSubModel
    ]
  );

  if (!isOpen) return null;

  return (
    <ModalCustom open={isOpen} onClose={onClose} width={400}>
      <Modalhead>
        <Headline>Invite member to {projectName} project.</Headline>
        <Icon1 onClick={onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='closeicon' />
        </Icon1>
      </Modalhead>
      {renderMemberList(renderData)}
    </ModalCustom>
  );
};

export default InviteMemberModal;
