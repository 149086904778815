import { makeAutoObservable } from 'mobx';
import { createObservableTask, TaskModel } from '../models/task';
import { RawTask } from '../interfaces/task';
import { genericUpsert, genericRemove } from '../common';

export class TaskStore {
  tasks: TaskModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTasks(rawTasks: RawTask[]) {
    this.tasks = rawTasks.map((rawTask) => createObservableTask(rawTask));
  }

  upsertTasks(rawTasks: RawTask[]) {
    const { newItems, updatedItems } = genericUpsert(this.tasks, rawTasks, createObservableTask);

    return { newItems, updatedItems };
  }

  removeTasks(taskIds: string[]) {
    this.tasks = genericRemove(this.tasks, taskIds);
  }
}

export const taskStore = new TaskStore();
