import {
  Assigngroup,
  AvatarImage,
  Bottom,
  Bottomright,
  Card,
  Check,
  Checkbox,
  Coloricon,
  Firstcards,
  Group,
  IconDueDate,
  Icondiv,
  Inputvalue2,
  Label,
  LabelIcon,
  Milestone,
  OverLayDiv,
  Righttext,
  Textdate,
  TooltipDiv,
  Top,
  Topline
} from './styles';
import Avatar from '../avatar/Avatar';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import { TaskDetailsInterface, TaskGroupInterface } from '../../interfaces/TaskInterface';
import { MilestoneListInterface, StatusListInterface } from '../../interfaces/ProjectInterface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AssignBox from '../dropdowns/assignPopup';
import { calculateDueTime, isEmpty } from '../../helpers/common';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { setMyTaskDetailsData } from '../../actions/taskActions';
import { useHistory } from 'react-router-dom';
import { COMMENT_TYPE, LABEL_COLOUR_HASH } from '../../global/constants';
import Linkify from 'react-linkify';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { createNewComment, updateTaskDetails } from '../../services/taskServices';
import moment from 'moment';
import TaskStatusDropdown from '../dropdowns/taskStatusDropdown';
import Prioritydropdown from '../dropdowns/priorityDropdown';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';
import Tooltip from '../Tooltip';
import { Dropdown, DropdownItem } from '../Dropdown';

interface Props {
  taskDetails: TaskDetailsInterface;
  loadData?: () => void;
  isTaskGrid?: boolean;
  isOverdue?: boolean;
  onClickCurrentTaskCheckbox?: (e: React.SyntheticEvent) => void;
  onClickView?: () => void;
  onClickEdit?: () => void;
  onClickClone?: () => void;
  onClickDelete?: () => void;
  onUpdateMilestone?: (milestone: MilestoneListInterface) => void;
  onRemoveMilestone?: () => void;
  onUpdateStatus?: (status: StatusListInterface, previousStatus: StatusListInterface) => void;
}
export default function Calendarviewcard(Props: Props) {
  const {
    taskDetails,
    loadData,
    isTaskGrid,
    onClickCurrentTaskCheckbox,
    onUpdateMilestone,
    onRemoveMilestone,
    onUpdateStatus
  } = Props || {};
  //States
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    project: projectSelector,
    task: tasksSelector,
    workspace: workspaceSelector,
    auth: authSelector
  } = stateSelector || {};
  const { currentProject, milestoneList } = projectSelector;
  const { taskGroups } = tasksSelector;
  const { workspace } = workspaceSelector;
  const { userDetails } = authSelector;
  //Other variables
  const dispatch = useDispatch();
  const history = useHistory();
  let statusDetails: StatusListInterface | undefined;

  if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
    if (!taskDetails?.statusId) statusDetails = currentProject?.defaultStatus;
    else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === taskDetails?.statusId);
  }

  const loadGroupName = useCallback(async () => {
    if (!isEmpty(taskDetails?.groupId) && !isEmpty(workspace?.id)) {
      const group = taskGroups.find((x) => x?.id === taskDetails?.groupId);
      if (group) setGroupName(group?.name);
      else setGroupName('');
    }
  }, [taskDetails?.groupId, taskGroups, workspace?.id]);

  useEffect(() => {
    loadGroupName();
  }, [loadGroupName]);

  const onClickTaskDetails = useCallback(() => {
    const eventProperties = {
      source: 'task list',
      title: taskDetails?.name || ''
    };
    trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
    dispatch(setMyTaskDetailsData(taskDetails));
    history.push(`/projects/details/${taskDetails?.projectId}/tasks?task=${taskDetails?.id}`);
  }, [dispatch, history, taskDetails]);

  const updateTaskGroup = useCallback(
    async (item: TaskGroupInterface) => {
      if (item?.id !== taskDetails?.groupId) {
        const previousGroup = taskGroups?.find((x) => x?.id === taskDetails?.groupId);
        const payload: TaskDetailsInterface = {
          ...taskDetails,
          companyId: workspace?.id,
          groupId: item?.id
        };
        if (payload?.subscribers) {
          delete payload?.subscribers;
        }
        if (payload?.createdBy) {
          delete payload?.createdBy;
        }
        setGroupName(item?.name);
        const payloadTask = { groupId: item?.id, projectId: taskDetails?.projectId };
        const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(taskDetails?.id, {
            Type: COMMENT_TYPE.CHANGE_GROUP,
            PreviousGroup: previousGroup?.name,
            Group: item?.name
          })
        );

        if (result && commentResult) {
          if (loadData) loadData();
          trackAnalyticActivity(TASK_ANALYTICS.GROUP_CHANGED);
        }
      }
    },
    [dispatch, loadData, taskDetails, taskGroups, workspace?.id]
  );

  const getMilestoneName = useMemo(() => {
    if (currentProject?.isMilestone && !isEmpty(taskDetails?.milestoneId)) {
      const milestoneName = milestoneList?.find((item) => item?.id === taskDetails?.milestoneId);
      return milestoneName?.milestoneName;
    }
  }, [currentProject?.isMilestone, milestoneList, taskDetails?.milestoneId]);

  const dueResult = useMemo(() => {
    if (taskDetails?.dueDate) {
      return calculateDueTime(taskDetails.dueDate);
    }
  }, [taskDetails?.dueDate]);

  return (
    <>
      <Card isTaskGrid={isTaskGrid} onClick={onClickTaskDetails}>
        <Firstcards isTaskGrid={isTaskGrid}>
          <Topline>
            <Top isTaskGrid={isTaskGrid}>
              {isTaskGrid && currentProject?.isMilestone && taskDetails?.milestoneId && (
                <Dropdown content={<Milestone>{getMilestoneName}</Milestone>} trigger='click'>
                  <DropdownItem key={0} label='No Milestone' onClick={() => onRemoveMilestone?.()} />
                  {milestoneList?.map((item) => {
                    return (
                      <DropdownItem key={0} label={item?.milestoneName} onClick={() => onUpdateMilestone?.(item)} />
                    );
                  })}
                </Dropdown>
              )}
              {!isTaskGrid && (
                <Dropdown content={<Group>{groupName || 'Select Group'}</Group>} trigger='click'>
                  {taskGroups?.map((item) => {
                    return <DropdownItem label={item?.name} key={item?.id} onClick={() => updateTaskGroup(item)} />;
                  })}
                </Dropdown>
              )}

              {isTaskGrid ? (
                <Check>
                  {statusDetails && onUpdateStatus ? (
                    <TaskStatusDropdown
                      defaultStatus={currentProject?.defaultStatus}
                      statusList={currentProject?.statusData}
                      currentStatusId={taskDetails?.statusId}
                      isTaskCheckBox={true}
                      onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                        onUpdateStatus(status, previousStatus)
                      }
                      isHoverIcon={true}
                    />
                  ) : (
                    <Checkbox onClick={(e) => e.stopPropagation()}>
                      <Inputvalue2
                        type='checkbox'
                        className='checkbox-round'
                        checked={taskDetails?.status}
                        onChange={(e) => onClickCurrentTaskCheckbox?.(e)}
                      />
                    </Checkbox>
                  )}
                  <Linkify
                    componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                      <a target='blank' href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}>
                    <abbr title={taskDetails?.name}>
                      <Label>{taskDetails?.name || ''}</Label>
                    </abbr>
                  </Linkify>
                </Check>
              ) : (
                <abbr title={taskDetails?.name}>
                  <Label>{taskDetails?.name || ''}</Label>
                </abbr>
              )}
              {taskDetails?.labels?.length > 0 && currentProject?.labelsEnabled && (
                <Icondiv onClick={(e) => e.stopPropagation()}>
                  {taskDetails?.labels?.map((item, index: number) => {
                    return (
                      <Coloricon key={item?.id} className={index !== 0 ? 'label-with-border' : ''}>
                        <LabelIcon color={`${LABEL_COLOUR_HASH[item?.color]}`} />
                        <Righttext>{item?.name}</Righttext>
                      </Coloricon>
                    );
                  })}
                </Icondiv>
              )}
            </Top>
          </Topline>

          <Bottom>
            <Bottomright>
              {/* priority dropdown for task grid */}
              {currentProject?.priority?.enabled && isTaskGrid && (
                <div style={{ position: 'relative' }}>
                  <Prioritydropdown
                    taskData={taskDetails}
                    icon={true}
                    loadData={loadData}
                    isTaskItem={true}
                    currentProject={currentProject}
                    isTaskCheckBox={true}
                    isHoverIcon={true}
                  />
                </div>
              )}
              <Assigngroup className='assign' onClick={(e) => e.stopPropagation()}>
                <ul>
                  {taskDetails?.users
                    ?.filter((item) => !isEmpty(item?.id))
                    ?.map((user) => {
                      return (
                        <li key={user?.id}>
                          <AvatarImage>
                            <Avatar
                              imgSrc={user?.profile_photo}
                              name={user?.name ? user.name : 'U N'}
                              size={20}
                              isMilestoneBorder
                            />
                          </AvatarImage>
                        </li>
                      );
                    })}
                  <AssignBox
                    setIsDropdownOpen={setIsAssignDropdownOpen}
                    isDropdownOpen={isAssignDropdownOpen && currentAssignBox === taskDetails?.id}
                    projectUsers={currentProject?.users}
                    currentTask={taskDetails}
                    loadData={loadData}
                    setCurrentAssignBox={(id) => setCurrentAssignBox(id)}
                    currentAssignBox={currentAssignBox}
                    isCalendarview={true}
                    className={
                      !isTaskGrid && taskDetails?.users?.filter((item) => !isEmpty(item?.id))?.length > 2
                        ? 'large-margin'
                        : !isTaskGrid
                        ? 'small-margin'
                        : ''
                    }
                    isMultiAssignee={currentProject?.multiAssignee}
                  />
                </ul>
              </Assigngroup>
            </Bottomright>
            <Bottomright>
              {isTaskGrid && !isEmpty(taskDetails?.dueDate) && (
                <Tooltip
                  title={
                    <>
                      <div>Due {dueResult?.dueParam}</div>
                      <div> Due on {moment(taskDetails?.dueDate).format(userDetails?.dateFormat)}</div>
                    </>
                  }
                  placement='bottom'
                  maxWidth={'max-content'}>
                  <TooltipDiv>
                    <Textdate isOverdue={dueResult?.overDue} className={taskDetails?.status ? 'task-completed' : ''}>
                      {dueResult?.dueTime}
                    </Textdate>

                    <IconDueDate>
                      {dueResult?.overDue && !taskDetails?.status ? (
                        <SVGIcon
                          name='tasklist-calendar-duedate-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-due-fill-color'
                        />
                      ) : (
                        <SVGIcon
                          name='tasklist-calendar-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-fill-color'
                        />
                      )}
                    </IconDueDate>
                  </TooltipDiv>
                </Tooltip>
              )}
              {/* priority dropdown for calendar view */}
              {currentProject?.priority?.enabled && !isTaskGrid && (
                <div style={{ position: 'relative' }}>
                  <Prioritydropdown
                    taskData={taskDetails}
                    icon={true}
                    loadData={loadData}
                    isTaskItem={true}
                    currentProject={currentProject}
                    isCalendarviewCard
                    isCalendarviewtasklist
                    isTaskCheckBox={true}
                    isHoverIcon={true}
                  />
                </div>
              )}
            </Bottomright>
          </Bottom>
        </Firstcards>
      </Card>
      {!isEmpty(currentAssignBox) && isAssignDropdownOpen && (
        <OverLayDiv
          className='dropdown-overlay'
          onClick={() => {
            setIsAssignDropdownOpen(false);
            setCurrentAssignBox('');
          }}
        />
      )}
    </>
  );
}
