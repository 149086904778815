import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isWidth?: boolean;
  isFontWeight?: boolean;
  isborder?: boolean;
  isborderbottom?: boolean;
  isborderTop?: boolean;
  isScrollTable?: boolean;
}

const Smallheader = styled.th<Props>`
  background: var(--background-tertiary);
  color: var(--text-secondary);
  ${Typography.body_sm_medium}
  text-transform: uppercase;
  padding: 10px 12px;
  text-align: start;
  white-space: nowrap;
  border-top: 0.5px solid var(--border-primary);

  ${(props) =>
    props.className === 'no-border-right' &&
    css`
      border-right: none;
    `}

  ${(props: Props) =>
    props.isborderbottom &&
    css`
      border-bottom: 0.5px solid var(--border-primary);
    `}

    ${(props: Props) =>
    props.isScrollTable &&
    css`
      &:first-child {
        position: sticky;
        left: 0;
        box-shadow: var(--shadow-card);
        z-index: 2;
        @media (max-width: 449px) {
          position: relative;
          box-shadow: unset;
        }
      }

      &:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
        box-shadow: var(--shadow-card);
      }
    `}
`;
const Table = styled.table<Props>`
  border-collapse: separate;
  border-style: hidden;
  border-radius: 0 0 8px 8px;
  border: 0.5px solid var(--border-primary);
  border-top: none;
  border-spacing: 0;
  background: var(--background-primary);
  width: 100%;
  @media (max-width: 449px) {
    border: unset;
  }
  ${(props: Props) =>
    props.isScrollTable &&
    css`
      border: none;
    `}
`;
const Tabledata = styled.td<Props>`
  border-top: 0.5px solid var(--border-primary);
  text-align: start;
  padding: 12px;
  color: var(--text-primary);
  ${Typography.body_md_medium}
  ${(props: Props) =>
    props.isFontWeight &&
    css`
      font-weight: 700;
    `}
  ${(props: Props) =>
    props.isborderTop &&
    css`
      border-top: none;
    `}
  ${(props: Props) =>
    props.isWidth &&
    css`
      width: 20%;
    `}
  @media (max-width: 449px) {
    white-space: nowrap;
    min-width: 50px;
  }
  &:first-child {
    ${(props: Props) =>
      props.isWidth &&
      css`
        width: 60%;
      `}
  }
  &:first-child {
    ${(props: Props) =>
      props.isborder &&
      css`
        width: 43px;
        text-align: center;
        @media (max-width: 449px) {
          min-width: 30px;
        }
      `}
  }
  ${(props: Props) =>
    props.isborder &&
    css`
      padding: 10px 12px;
    `}

  .truncate-text {
    cursor: pointer;
    display: inline-flex;
    max-width: 249px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
  }
  .tooltip-text {
    max-width: 249px;
    width: max-content;
  }
  .screen-shot {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    border: 0.5px solid var(--border-primary);
  }
  .image-data {
    display: flex;
    gap: 18px;
    align-items: center;
    a {
      width: 36px;
      height: 36px;
    }
  }
  ${(props: Props) =>
    props.isScrollTable &&
    css`
      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        box-shadow: var(--shadow-card);
        background: var(--background-primary);
        @media (max-width: 449px) {
          position: relative;
          box-shadow: unset;
        }
      }

      &:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
        background: var(--background-primary);
        box-shadow: var(--shadow-card);
      }
    `}
`;
const Productname = styled.div<Props>`
  color: var(--text-primary);
  ${Typography.body_md_medium}
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;

  ${(props: Props) =>
    props.isFontWeight &&
    css`
      font-weight: 700 !important;
    `}
`;

const Link = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

const Icon = styled.div`
  height: 16px;
  width: 16px;
  .plus-minus-icon {
    path {
      stroke: var(--brand-primary);
    }
  }
`;
const TableWrapper = styled.div<Props>`
  ${(props: Props) =>
    props.isScrollTable &&
    css`
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      position: relative;
      border-radius: 0 0 8px 8px;
      border: 0.5px solid var(--border-primary);
      border-style: none solid solid solid;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  @media (max-width: 449px) {
    border-radius: 0 0 8px 8px;
    border: 0.5px solid var(--border-primary);
    border-top: none;
    width: calc(100vw - 33px);
    overflow: scroll;
    scrollbar-width: none;
  }
`;
const Empty = styled.div`
  padding: 41px 0;
`;

const Date = styled.div`
  .month {
    color: var(--brand-primary);
    display: block;
    ${Typography.body_sm_medium}
  }

  .day {
    color: var(--text-primary);
    display: block;
    margin-top: 3px;
    ${Typography.body_md_medium}
  }
  @media (max-width: 449px) {
    width: max-content;
  }
`;

export { Productname, Tabledata, Smallheader, Table, Link, Icon, Row, TableWrapper, Empty, Date };
