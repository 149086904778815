import styled from 'styled-components';

export const ColorPickerWrapper = styled.div`
  width: 240px;
  padding: 12px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ColorArea = styled.div<{ hue: number }>`
  position: relative;
  width: 100%;
  height: 150px;
  margin-bottom: 12px;
  background: ${(props) => `hsl(${props.hue}, 100%, 50%)`};
  cursor: crosshair;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &::before {
    background: linear-gradient(to right, #fff, transparent);
  }

  &::after {
    background: linear-gradient(to bottom, transparent, #000);
  }
`;

export const ColorThumb = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
`;

export const SpectrumSlider = styled.div`
  position: relative;
  width: 100%;
  height: 12px;
  margin-bottom: 12px;
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  border-radius: 6px;
  cursor: pointer;
`;

export const SpectrumThumb = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

export const ColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InputLabel = styled.span`
  font-size: 12px;
  color: #666;
`;

export const HexInput = styled.input`
  flex: 1;
  height: 28px;
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;
