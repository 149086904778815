/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  direction?: 'top' | 'bottom';
  horizontalPosition?: 'left' | 'right';
  isEmojiLoading?: boolean;
  isCommentModal?: boolean;
}

const Emojis = styled.div<Props>`
  position: absolute;
  z-index: 9999;

  aside.EmojiPickerReact.epr-main {
    border: 0.5px solid var(--border-primary) !important;
    border-radius: 12px !important;
    box-shadow: var(--shadow-modal) !important;
    margin-top: 5px !important;
  }
  .EmojiPickerReact {
    background-color: var(--background-primary);
  }
  .EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/Template_images%2Femoji_svg_ref.svg?alt=media&token=7fd738ed-1e10-4c18-8f91-a0c4b6c7ef5f);
  }
  .epr_-z2fuzw {
    padding: 4px 8px !important;
    background-color: var(--background-primary) !important;
    border-radius: 6px !important;
    border: 0.5px solid var(--border-primary) !important;
    color: var(--text-primary) !important;
    ${Typography.body_sm_medium}
    height: 25px !important;
  }
  .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
    ${Typography.body_xs_semibold}
    height: auto !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
    color: var(--text-secondary) !important;
  }
  .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
    background-color: var(--background-primary);
  }
  .EmojiPickerReact button.epr-emoji:hover > * {
    border-radius: 6px !important;
    background-color: var(--neutral-secondary) !important;
  }
  .epr-emoji-list {
    border-right: 0.5px solid var(--border-primary) !important;
  }
  .EmojiPickerReact .epr-search-container input.epr-search::placeholder {
    color: var(--text-placeholder) !important;
  }
  .EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
    background-color: var(--text-tertiary) !important;
    border-radius: 7px !important;
    min-height: 20px !important;
  }
  .EmojiPickerReact .epr-search-container input.epr-search:focus {
    border: 0.5px solid var(--brand-primary) !important;
  }
  .epr_jkst5z:focus {
    border-radius: 6px !important;
    background-color: var(--neutral-secondary) !important;
  }
  .epr_c90x4z {
    display: none;
  }
  ${({ isCommentModal }) =>
    isCommentModal &&
    css`
      position: relative;
      aside.EmojiPickerReact.epr-main {
        border: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        margin-top: unset !important;
      }
      .epr_-8ygbw8 {
        padding: 6px 6px 0 6px !important;
      }
      .epr_-4ueg5a {
        background-color: unset !important;
      }
      .epr_-to8mm {
        border-color: unset !important;
      }
      .epr_-khp9xq {
        border-radius: unset !important;
      }
      .epr_-rvl3je {
        border-style: unset !important;
      }
      .epr_-x928hz {
        border-width: 0 !important;
      }
    `}
  /* .EmojiPickerReact {
    --epr-bg-color: var(--background-primary);
    --epr-category-label-bg-color: var(--background-primary);
    --epr-hover-bg-color: var(--neutral-secondary);
    --epr-focus-bg-color: var(--neutral-secondary);
    --epr-search-border-color: var(--border-primary);
    --epr-category-label-text-color: var(--text-primary);
    border: none;
    border-radius: 8px;
  } */

  ${(props) =>
    props.isEmojiLoading &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

export { Emojis };
