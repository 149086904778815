import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isHide?: boolean;
  isGap?: boolean;
  isOverdue?: boolean;
  showRightBorder?: boolean;
  isTaskItem?: boolean;
  color?: string;
  isActive?: boolean;
  ismaintask?: boolean;
  isRecurringtask?: boolean;
  isMember?: boolean;
}

const Group = styled.div`
  margin: auto;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.div`
  display: inline-flex;

  .fill-color {
    width: 28px;
    height: 28px;
  }
  .svgicon {
    cursor: pointer;
    path {
      stroke: var(--text-tertiary);
      fill: var(--text-tertiary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  @media (max-width: 449px) {
    .fill-color {
      width: 24px;
      height: 24px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg};
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 449px) {
    gap: 16px;
    .hidebutton {
      display: none;
    }
    .responsive-button {
      height: 26px;
    }
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 12px 16px;
    margin: 0;
    width: -webkit-fill-available;
    background: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const ProjectName = styled.div<Props>`
  ${(props: Props) =>
    props.showRightBorder &&
    css`
      margin-right: 12px;
      padding-right: 12px;
      border-right: 0.5px solid var(--border-primary);
      ${props.isRecurringtask &&
      css`
        border: none;
        margin-right: 0;
        padding-right: 0;
      `}
    `}
  p {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-secondary);
    cursor: pointer;
    padding: 4px 8px;
    white-space: nowrap;

    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 23px;
    }
  }
  @media (max-width: 449px) {
    padding-right: 8px;
    p {
      padding: 0;
    }
  }
`;

const Task = styled.div<Props>`
  margin: 20px 0;
  ${(props) =>
    props.isMember &&
    css`
      @media (max-width: 449px) {
        margin: 12px 0 20px;
      }
    `}
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    vertical-align: middle;
  }
`;

const Form = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: ${(props: Props) => (props.isTaskItem ? '6px' : '10px')};
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
    background-color: var(--background-primary);
  }
  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  @media (max-width: 449px) {
    gap: 10px;
    .checkbox-round {
      padding: 8px;
    }
  }
`;

const Inputvalue = styled.input``;
const ButtonIcon = styled.div`
  padding: 4px;
  border-radius: 12px;
  display: flex;
  :hover {
    background-color: var(--neutral-secondary);
    input {
      filter: brightness(var(--brightness-value));
      background-color: transparent;
    }
  }
`;
const Iconhidden = styled.div`
  position: absolute;
  left: -15px;
  top: 9px;
  .svgicon {
    opacity: 0;
  }
  @media (max-width: 449px) {
    .svgicon {
      display: none;
    }
  }
`;

const Label = styled.label<Props>`
  width: auto;
  max-width: 920px;
  ${Typography.body_md};
  color: var(--text-primary);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  a {
    color: var(--text-link);
    text-decoration: underline;
    display: inline-block;
    :hover {
      text-decoration: none;
    }
  }
  @media (max-width: 449px) {
    max-width: 150px;
  }
  @media (max-width: 320px) {
    max-width: 90px;
  }
  ${(props) =>
    props.ismaintask &&
    css`
      @media (max-width: 449px) {
        max-width: 100%;
      }
    `}
`;

const Taskcontent = styled.div<Props>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid transparent;
  padding: 3px 5px;
  margin: 0 -10px;
  border-radius: 34px;
  gap: 40px;
  :hover .svgicon {
    opacity: 1;
  }

  :hover .plus-icon {
    visibility: visible;
    stroke: var(--background-primary);
  }

  :hover .task-input {
    background-color: var(--text-white);
  }

  :hover {
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
  }

  ${(props: Props) =>
    props.isActive &&
    css`
      background: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary) !important;
      box-shadow: var(--shadow-card) !important;
    `}

  .respassign {
    display: none;
  }
  @media (max-width: 449px) {
    align-items: center;
    gap: 8px;
    border: none;
    padding: 10px;
    .respassign {
      display: block;
    }
    .assign {
      display: none;
    }
    .webdropdown {
      display: none;
    }
    :hover {
      border: none;
      box-shadow: none;
      background: none;
    }
    :hover .svgicon {
      opacity: 1;
    }
    .calendar-icon {
      visibility: visible;
    }
    &.task-item {
      padding: 8px 10px;
    }
  }
`;

const Taskitem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 449px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const Assigngroup = styled.div`
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: max-content;
  }

  li {
    margin-left: -10px;
    border-radius: 50%;
    background-color: var(--background-primary);
    @media (max-width: 449px) {
      :first-child {
        margin-left: 0;
      }
    }
  }

  .plus-icon {
    visibility: hidden;
  }
`;

const Text = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;

  ${(props: Props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error);
    `}
  @media (max-width: 449px) {
    margin-left: -2px;
  }
`;

const ProjectTitle = styled.h1`
  ${Typography.heading_md};
  color: var(--text-primary);
  margin: 0;
  padding-bottom: 6px;
`;

const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;

const Tasklist = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props: Props) =>
    props.isHide &&
    css`
      display: none;
    `}

  ${(props: Props) =>
    props.isGap &&
    css`
      margin-top: 20px;
    `}
`;

const Taskicons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
`;
const TaskDropdown = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card-hover);
  border-radius: 8px;
`;

const Filter = styled.div<Props>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 5px 14px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 16px;
  box-shadow: var(--shadow-card);

  &:hover {
    background-color: var(--background-secondary);
  }
  .filters-icon-color {
    fill: var(--text-secondary);
  }
  ${(props: Props) =>
    props.isHide &&
    css`
      border: none;
      background: none;
      box-shadow: none;
      padding: 8px;
      :hover {
        background: var(--neutral-secondary);
        border-radius: 16px;
      }
    `}
  @media (max-width: 449px) {
    padding: 8px;
    border: none;
    box-shadow: unset;
  }
`;

const Selecttext = styled.div<Props>`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  padding-left: 6px;
  ${(props: Props) =>
    props.isHide &&
    css`
      display: none;
    `}
  @media (max-width: 449px) {
    display: none;
  }
`;

const DropText = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  padding: 6px 12px;
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
  }
`;

const Duedate = styled.div`
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;
const Projectdate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 449px) {
    display: none;
  }
`;
const Tasktitle = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;
const Maintasktitle = styled.div`
  max-width: 200px;
  ${Typography.body_md}
  color: var(--text-secondary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  a {
    color: var(--text-link);
    text-decoration: underline;
    display: inline;
    :hover {
      text-decoration: none;
    }
  }
  @media (max-width: 1086px) {
    max-width: 120px;
  }
  @media (max-width: 1004px) {
    max-width: 80px;
  }
  @media (max-width: 449px) {
    max-width: 150px;
  }
  @media (max-width: 320px) {
    max-width: 90px;
  }
`;
const Dropblock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 9px 3px 5px;
  border-left: 0.5px solid var(--border-primary);
  &.left-border {
    border-left: none;
  }
`;
const Doticon = styled.div<Props>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Droplabel = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;
const Taskcount = styled.div`
  margin-left: 2px;
  padding: 3px 9px;
  border: 0.5px solid var(--border-primary);
  border-radius: 20px;
  color: var(--text-primary);
  ${Typography.body_md};
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  @media (max-width: 449px) {
    margin-left: -2px;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
    `}
`;
const Multiplelabel = styled.div`
  border-radius: 15px;
  border: 0.5px solid var(--border-primary);
  display: flex;
  @media (max-width: 449px) {
    display: none;
  }
`;

const IconDueDate = styled.div<Props>`
  .fill-color {
    width: 16px;
    height: 16px;
    fill: var(--text-secondary);
  }

  &:hover .fill-color {
    fill: var(--text-primary);
  }
  &:active .fill-color {
    fill: var(--text-primary);
  }
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
  display: flex;
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    white-space: nowrap;
    margin: 0;
    h6 {
      ${Typography.body_md}
      color: var(--text-secondary);
      white-space: nowrap;
      margin: 0;
      :hover {
        color: var(--text-primary);
      }
    }
  }

  .task-grid-calander-icon {
    display: none;
    padding: 3px;
    border: 0.5px solid var(--border-primary);
    border-radius: 4px;
    width: 16px;
    height: 16px;
    path {
      fill: var(--text-secondary);
    }
  }
  .task-grid-calander-icon:hover {
    background-color: var(--neutral-secondary);
    border: 0.5px solid transparent;
  }
`;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 449px) {
    background: var(--background-overlay);
    z-index: 1111;
  }
`;

const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;
const HeaderTitle = styled.div`
  display: flex;
  gap: 24px;
`;
const Div = styled.div<Props>`
  @media (max-width: 449px) {
    margin: 72px 0 0;
  }
  ${(props) =>
    props.isMember &&
    css`
      @media (max-width: 449px) {
        margin: 0;
      }
    `}
`;
const Emptybox = styled.div`
  position: absolute;
  top: 50%;
  left: auto;
  transform: translate(0%, -50%);
  @media (max-width: 449px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ShowSubTask = styled.div`
  display: flex;
  padding-left: 8px;
`;
const SubTaskList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const LineDiv = styled.div`
  width: 5px;
  border: 0.5px solid var(--border-secondary);
  border-radius: 0 0 0 4px;
  border-style: none none solid solid;
  margin-bottom: 18px;
`;
const Showhide = styled.a`
  ${Typography.body_md}
  color: var(--text-secondary);
  cursor: pointer;
  max-width: 146px;
  width: 100%;
  padding: 4px 0 4px 10px;
  :hover {
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    padding: 4px 0;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export {
  Group,
  Heading,
  Icon,
  Title,
  Menu,
  Text,
  Label,
  TaskHeader,
  ProjectName,
  Task,
  Deadline,
  Form,
  Inputvalue,
  Iconhidden,
  Taskcontent,
  Taskitem,
  ProjectTitle,
  Assigngroup,
  Tasklist,
  AvatarImage,
  Taskicons,
  TaskDropdown,
  Filter,
  DropText,
  Duedate,
  Projectdate,
  Selecttext,
  Tasktitle,
  Maintasktitle,
  Doticon,
  Droplabel,
  Dropblock,
  Taskcount,
  Multiplelabel,
  IconDueDate,
  OverLayDiv,
  HeaderTitle,
  ResponsiveNavbarIcon,
  Div,
  Emptybox,
  Empty,
  ShowSubTask,
  LineDiv,
  SubTaskList,
  Showhide,
  ButtonIcon,
  TooltipWrapper
};
