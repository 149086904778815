import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Accounting from '../../../../component/accounting';
import AppLayout from '../../../../component/appLayout';
import {
  AddedLinkBox,
  AvatarImage,
  Div,
  Dropdowns,
  FileEmptystate,
  Graphbox,
  Group,
  Header,
  Heading,
  Leftdiv,
  LinkIcon,
  Loadingimage,
  Milestone,
  Milestonebox,
  MoreIcon,
  Podcastdiv,
  Profileborder,
  Profilegroup,
  Profiles,
  ResourceTitle,
  ResourcesDrodpownHeading,
  ResourcesFileSection,
  ResourcesSection,
  Rightdiv,
  Status,
  Subdiv,
  Taskdiv,
  Text,
  Top,
  Viewdropdown,
  WrapperDiv
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFileData,
  addInvitedMember,
  addProjectResource,
  deleteProjectResource,
  getCurrentProjectDetails,
  getDocumentData,
  getMilestoneList,
  getProjectFiles,
  removeInvitedMember
} from '../../../../services/projectServices';
import { useHistory, useParams } from 'react-router-dom';
import { setProjectActivity, setProjectFiles } from '../../../../actions/projectActions';
import { captureException } from '../../../../services/logService';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import {
  calculateDueTime,
  extensionResource,
  floatToHHMM,
  formatBytes,
  getMonthRange,
  getUTCDate,
  handleFileUpload,
  isEmpty
} from '../../../../helpers/common';
import Button from '../../../../component/button';
import Avatar from '../../../../component/avatar/Avatar';
import Taskcard from './taskcard';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import { getTasksList } from '../../../../services/taskServices';
import {
  FILE_EXTENTIONS,
  FILE_TYPES,
  FILES_MENU_OPTIONS,
  LINK_FILE_DATA,
  MENU_OPTIONS,
  RESOURCE_TYPE,
  TimeLogmenuItems,
  USER_ROLE
} from '../../../../global/constants';
import milestonelightempty from '../../../../assets/images/emptystates/milestonelightempty.svg';
import milestonedarkempty from '../../../../assets/images/emptystates/milestonedarkempty.svg';
import { getCustomerMembersList } from '../../../../services/customerServices';
import UserPreferenceSingleton from '../../../../helpers/userPreferenceSingleton';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import Inviteteamsmodal from '../../../../component/models/inviteteamsmodal';
import ModalCustom from '../../../../component/models/modal';
import MilestoneModal from '../../../../component/models/milestoneModal';
import LineChart from '../../../../component/chart/chart';
import moment from 'moment';
import {
  ChartDataInterface,
  ChartDetailsInterface,
  SelectionRangeInterface,
  TimeLogInterface
} from '../../../../interfaces/TimeReportInterface';
import { loadTimeLogs } from '../../../../services/timeTrackingServices';
import EmptyState from '../../../../component/emptyState';
import ReportTimelineLoading from '../../../../component/loading/reporttimelineLoading';
import { PROJECT_ANALYTICS } from '../../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../../../component/Dropdown';
import { LinkFileModal } from '../../../../component/models/linkFileModal';
import FileSVGIcon from '../../../../assets/images/svg/filesIconSvg';
import RenderExistingFile from '../../../../component/renderExistingFile';
import { useMobileDevice } from '../../../../global/useMobile';
import { setErrorMessage, setSuccessMessage } from '../../../../actions/messageActions';
import { CommentFilesInterface } from '../../../../interfaces/TaskInterface';
import {
  ProjectFileData,
  ProjectResourceDetailInterface,
  ProjectResourceInterface
} from '../../../../interfaces/ProjectInterface';
import FilesModal from '../../../../component/models/filesmodal';
import Deletemodal from '../../../../component/models/deleteModel';
import copy from 'clipboard-copy';
import Tooltip from '../../../../component/Tooltip';
import FileUploadCard from '../../../../component/FileUploadCard';
import { rootStore } from '../../../../mobx/rootStore';
import { observer } from 'mobx-react-lite';
import { UserModel } from '../../../../mobx/models/user';
import CircularProgress from '../../../../component/CircularProgress';
import InviteMemberModal from '../../../../component/models/inviteMemberModal/inviteMemberModal';

//menu items for dropdown action list for file
const fileMenuItems = [
  {
    key: FILES_MENU_OPTIONS.VIEW,
    label: 'View'
  },
  {
    key: FILES_MENU_OPTIONS.RENAME,
    label: 'Rename'
  },
  {
    key: FILES_MENU_OPTIONS.DELETE,
    label: 'Delete',
    tone: 'critical'
  }
];

//menu items for dropdown action list for added link
const menuItems = [
  {
    key: MENU_OPTIONS.COPYLINK,
    label: 'Copy link'
  },
  {
    key: MENU_OPTIONS.EDIT,
    label: 'Edit'
  },
  {
    key: MENU_OPTIONS.DELETE,
    label: 'Delete',
    tone: 'critical'
  }
];

const Overview: React.FC = () => {
  //Ref
  const fileInputRef = useRef<HTMLInputElement>(null);

  // States
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [tasksCount, setTasksCount] = useState<{
    completedTasks: number;
    inCompletedTasks: number;
    overDueTasks: number;
  }>({ completedTasks: 0, inCompletedTasks: 0, overDueTasks: 0 });
  const [clientList, setClientList] = useState<UserModel[]>([]);
  const [workspaceUsers, setWorkspaceUsers] = useState<UserModel[]>([]);
  const [isMainModel, setMainModel] = useState(false);
  const [isSubModel, setSubModel] = useState(false);
  const [searchResult, setSearchResult] = useState<UserModel[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [query, setQuery] = useState('');
  const [chartLoading, setChartLoading] = useState(false);
  const [isWorkspaceUser, setIsWorkspaceUser] = useState(true);
  const [isAddMilestoneModalOpen, setIsAddMilestoneModalOpen] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [isFileModalOpen, setIsFileModelOpen] = useState(false);
  const [itemData, setItemData] = useState<ProjectFileData | null>(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [uploadFileDetail, setUploadFileDetail] = useState<File[]>();

  const [selectedTimelogFilter, setSelectedTimelogFilter] = useState('Last 7 days');
  const [timeChartData, setTimeChartData] = useState<ChartDataInterface>({ data: [], x_axis_data: [] });
  const [selectionRange, setSelectionRange] = useState<SelectionRangeInterface>({
    startDate: new Date(moment().clone().subtract(7, 'days').format('ddd MMM D YYYY 00:00:00 [GMT+0530]')),
    endDate: new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]')),
    key: 'selection'
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectFile, setSelectFile] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector } = stateSelector || {};
  const { milestoneList, projectAllFiles, uploadFilePercent } = projectSelector;
  const { themeMode } = settingsSelector;
  //Mobx store variables
  const { projectStore, workspaceStore } = rootStore;
  const { currentProject } = projectStore;
  const { currentWorkspace: workspace } = workspaceStore;
  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const mobile = useMobileDevice();
  const history = useHistory();

  // get image based on theme
  const getImageSource = () => (themeMode?.theme === 'dark' ? milestonedarkempty : milestonelightempty);

  // for open dropdown
  const handleDropdownOpen = useCallback(() => {
    setOpenDropdown(!openDropdown);
  }, [openDropdown]);

  // for open model
  const openModel = useCallback(() => {
    setIsOpen(true);
  }, []);

  const attachmentPage = () => {
    const withoutParentData = projectAllFiles?.filter((x) => isEmpty(x?.parentId));
    dispatch(setProjectFiles(withoutParentData));
    if (mobile) {
      setSelectFile(true);
    } else {
      setAttachment(true);
    }
  };

  // for cancel model
  const cancelModel = useCallback(() => {
    setIsOpen(false);
    setItemData(null);
  }, []);

  // login for load time data
  const loadTimeData = useCallback(async () => {
    try {
      setChartLoading(true);
      const monthRange = getMonthRange(selectionRange?.startDate, selectionRange?.endDate);
      const timeReportData = await dispatch(loadTimeLogs(workspace?.id, monthRange));
      let chart_data: ChartDetailsInterface[] = [];
      let timeLogData: TimeLogInterface[] = JSON.parse(JSON.stringify(timeReportData || []));
      const startDate = moment(getUTCDate(selectionRange.startDate));
      const endDate = moment(getUTCDate(selectionRange.endDate)).add(1, 'day');
      timeLogData = timeLogData?.filter(
        (item) =>
          item?.projectId === currentProject?.id && moment(item?.date) >= startDate && moment(item?.date) <= endDate
      );
      workspace?.users?.forEach((item) => {
        let data = [];
        let total_time = 0;
        const user = workspace?.users?.find((user) => user?.id === item?.id);
        let startDateRef = moment(selectionRange.startDate);
        while (startDateRef.isBefore(endDate, 'day')) {
          const logs = timeLogData?.filter(
            (x) =>
              x?.createdBy === item?.id && moment(x?.date).format('YYYY-MM-DD') === startDateRef.format('YYYY-MM-DD')
          );
          const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
            return acc + obj.hours;
          }, 0);
          total_time = total_time + totalHours;
          data?.push(floatToHHMM(totalHours));
          startDateRef.add(1, 'day');
        }

        const dataObj = { id: item?.id, name: user?.name || '', data: data };
        chart_data.push(dataObj);
      });
      let startDateRef = moment(selectionRange.startDate);
      let x_axis_data: string[] = [];
      while (startDateRef.isBefore(endDate, 'day')) {
        x_axis_data.push(startDateRef.format('DD MMM'));
        startDateRef.add(1, 'day');
      }
      setTimeChartData({ data: chart_data, x_axis_data });
    } catch (e) {
      captureException(e);
    } finally {
      setChartLoading(false);
    }
  }, [currentProject?.id, dispatch, selectionRange.endDate, selectionRange.startDate, workspace?.id, workspace?.users]);

  // load initial data
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!isEmpty(workspace?.id)) {
        const promises = [dispatch(getTasksList(params?.id, true))];
        if (currentProject?.isMilestone) {
          promises.push(dispatch(getMilestoneList(params?.id, false, false)));
        }
        if (workspace?.isAdmin || workspace?.isOwner) {
          loadTimeData();
        }
        if (!isEmpty(currentProject?.customerId) && (workspace?.isAdmin || workspace?.isOwner)) {
          promises.push(dispatch(getCustomerMembersList(currentProject?.customerId)));
        }
        await dispatch(getProjectFiles(params?.id, currentProject?.users, true));
        const result = await Promise.all(promises);
        const completedTasks = result[0]?.filter((item: { status: boolean }) => item?.status)?.length;
        const inCompletedTasks = result[0]?.filter((item: { status: boolean }) => !item?.status)?.length;
        const overDueTasks = result[0]?.filter(
          (item: { status: boolean; dueDate: string }) => !item?.status && calculateDueTime(item?.dueDate)?.overDue
        )?.length;
        setTasksCount({ completedTasks, inCompletedTasks, overDueTasks });
        if (!isEmpty(currentProject?.customerId) && workspace?.isAdmin) {
          const filteredCustomers =
            result[result.length - 1]?.length > 0 && workspace?.users?.length > 0
              ? workspace?.users?.filter(
                  (item) =>
                    item?.role === USER_ROLE.CLIENT &&
                    result[result.length - 1]?.some((a: { id: string }) => a?.id === item?.id)
                )
              : [];

          setClientList(filteredCustomers);
        }
        const filteredWorkspaceUsers = workspace?.users?.filter((item) => item?.role !== USER_ROLE.CLIENT) || [];
        setWorkspaceUsers(filteredWorkspaceUsers);
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadTimeData, params?.id, workspace?.id, workspace?.isAdmin, workspace?.isOwner, workspace?.users]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setProjectActivity([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  // logic for search query
  const searchQuery = useCallback(
    (value: string) => {
      let result;
      if (isWorkspaceUser) {
        result = workspaceUsers?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      } else {
        result = clientList?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      }
      if (result) {
        setSearchResult(result);
        return null;
      }
    },
    [clientList, isWorkspaceUser, workspaceUsers]
  );

  // for change search
  const onChangeSearch = useCallback(
    (e: { target: { value: string } }) => {
      const value = e.target.value;
      setQuery(value);
      let timeout;
      if (value) {
        if (!isSearch) setIsSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQuery(value);
        }, 300);
      } else {
        if (isSearch) setIsSearch(false);
        setSearchResult([]);
      }
    },
    [isSearch, searchQuery]
  );

  // for close invite model
  const closeInviteModal = useCallback(() => {
    setMainModel(false);
    setIsWorkspaceUser?.(true);
    setQuery('');
    setIsSearch(false);
  }, []);

  // for invite member
  const onSubModel = () => {
    setMainModel(false);
    setSubModel(true);
  };

  // for close sub model
  const onCloseSubModal = () => {
    setSubModel(false);
  };

  // handle work space button
  const onClickWorkspaceButton = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (setIsWorkspaceUser) setIsWorkspaceUser(true);
      setQuery('');
      setIsSearch(false);
    },
    [setIsWorkspaceUser]
  );

  // handle client button click
  const onClickClientButton = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (setIsWorkspaceUser) setIsWorkspaceUser(false);
      setQuery('');
      setIsSearch(false);
    },
    [setIsWorkspaceUser]
  );

  // handle member box click
  const onClickMemberBox = useCallback(
    async (userIndex: number | undefined, invitedUser: UserModel) => {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      try {
        setLoading(true);
        let result;
        let usersClone = JSON.parse(JSON.stringify(currentProject?.users));
        if (userIndex === -1) {
          const payload = {
            userId: userDetails?.id,
            newUserId: invitedUser?.id,
            companyId: workspace?.id,
            projectId: currentProject?.id
          };
          usersClone = [...(usersClone || []), invitedUser];
          currentProject?.addUser(invitedUser?.id);
          result = await dispatch(addInvitedMember(payload));
          trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_ADDED);
        } else {
          usersClone.splice(userIndex, 1);
          currentProject?.removeUser(invitedUser?.id);
          result = await dispatch(removeInvitedMember(userDetails?.id, currentProject?.id, invitedUser?.id));
          trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_REMOVED);
        }
        if (result) {
          await dispatch(getCurrentProjectDetails(workspace?.id, currentProject?.id));
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [currentProject, workspace?.id, dispatch]
  );

  const handleFileInput = useCallback(() => {
    queueMicrotask(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    });
  }, []);

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        const fileArray = Array.from(files); // Convert FileList to Array
        setUploadFileDetail(fileArray);
        //call common function to upload file in firebase
        const uploadedFiles: CommentFilesInterface[] = (await handleFileUpload([], fileArray, dispatch)) || [];

        // //Handle file size
        let fileSize = 0;
        if (uploadedFiles[0]?.size) {
          fileSize = parseFloat(uploadedFiles[0]?.size); // Extracts the numeric value
          if (uploadedFiles[0]?.size?.toUpperCase().includes('MB')) {
            fileSize = fileSize * (1024 * 1024);
          } else {
            fileSize = fileSize * 1024;
          }
        }
        const payload = uploadedFiles?.map((file, index) => ({
          FileType: uploadedFiles[0].file_type,
          IsSystemFile: true,
          Name: uploadedFiles[0].name,
          ParentId: null,
          ProjectId: params?.id,
          Size: Number(fileSize),
          UploadFileType: FILE_TYPES.UPLOAD,
          Url: uploadedFiles[0]?.href,
          IsResource: true
        }));
        const response = await dispatch(addFileData(payload[0]));
        if (response) {
          const data = response.data;
          const resource = {
            CreatedBy: data.CreatedBy,
            CreatedTime: data.CreatedTime,
            IsArchived: data.IsArchived,
            Label: uploadedFiles[0]?.name,
            Reference: uploadedFiles[0]?.href,
            UpdatedBy: data.UpdatedBy,
            Type: RESOURCE_TYPE.FILE,
            UpdatedTime: data.UpdatedTime,
            _id: data['_id'],
            FileType: uploadedFiles[0]?.file_type,
            UploadFileType: FILE_TYPES.UPLOAD
          };
          currentProject?.addResource(resource);
          setUploadFileDetail([]);
          dispatch(setSuccessMessage('File uploaded successfully'));
        }
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      }
    },
    [currentProject, dispatch, params?.id]
  );

  const onSelectFile = useCallback(
    async (item: any) => {
      if (!item) return;

      const { name, uploadFileType, parentId, id, size, url, fileExt } = item;
      const isDocOrWhiteboard = uploadFileType === FILE_TYPES.DOCS || uploadFileType === FILE_TYPES.WHITEBOARD;

      let fileUrl = url;
      if (isDocOrWhiteboard) {
        fileUrl = `/projects/files/${currentProject?.id}/docs/${id}${!isEmpty(parentId) ? `?folder=${parentId}` : ''}`;
      }

      const payload: ProjectResourceInterface = {
        id: id,
        label: name,
        reference: fileUrl,
        type: RESOURCE_TYPE.FILE,
        fileType: fileExt,
        isSystemFile: null,
        size: Number(size),
        uploadFileType: uploadFileType
      };

      const response = await dispatch(addProjectResource(currentProject?.id, payload));
      if (response) {
        currentProject?.addResource(response);
        dispatch(setSuccessMessage('File uploaded successfully'));
      }
    },
    [currentProject, dispatch]
  );

  const closeFileModel = useCallback(() => {
    setItemData(null);
    setIsFileModelOpen(false);
  }, []);

  const getRenderData = useCallback(() => {
    if (isSearch) {
      return searchResult;
    }
    return isWorkspaceUser ? workspaceUsers : clientList;
  }, [clientList, isSearch, isWorkspaceUser, searchResult, workspaceUsers]);

  // for manage time filter
  const onChangeTimeFilter = useCallback(({ key }: { key: string }) => {
    let startDate, endDate;
    setSelectedTimelogFilter(key);
    switch (key) {
      case 'Yesterday':
        startDate = new Date(moment().subtract(1, 'days').format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        endDate = new Date(moment().subtract(1, 'days').format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        break;
      case 'This week':
        startDate = new Date(moment().clone().startOf('week').format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        endDate = new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        break;
      case 'Last week':
        startDate = new Date(
          moment().clone().subtract(1, 'weeks').startOf('week').format('ddd MMM D YYYY 00:00:00 [GMT+0530]')
        );
        endDate = new Date(
          moment().clone().subtract(1, 'weeks').endOf('week').format('ddd MMM D YYYY 00:00:00 [GMT+0530]')
        );
        break;
      case 'Last 7 days':
        startDate = new Date(moment().clone().subtract(7, 'days').format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        endDate = new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        break;
      case 'This month':
        startDate = new Date(moment().clone().startOf('month').format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        endDate = new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        break;
      case 'Last month':
        startDate = new Date(
          moment().clone().subtract(1, 'months').startOf('month').format('ddd MMM D YYYY 00:00:00 [GMT+0530]')
        );
        endDate = new Date(
          moment().clone().subtract(1, 'months').endOf('month').format('ddd MMM D YYYY 00:00:00 [GMT+0530]')
        );
        break;
      default:
        startDate = new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        endDate = new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]'));
        break;
    }
    setSelectionRange({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      key: 'selection'
    });
  }, []);

  // for open milestone model
  const onOpenMilestoneModal = useCallback(() => {
    setIsAddMilestoneModalOpen(true);
  }, []);

  // for close milestone model
  const onCloseMilestoneModal = useCallback(() => {
    setIsAddMilestoneModalOpen(false);
  }, []);

  const renderedTimelogItems = useMemo(() => {
    return TimeLogmenuItems.map((item) => (
      <DropdownItem key={item} label={item} onClick={() => onChangeTimeFilter({ key: item })} />
    ));
  }, [onChangeTimeFilter]);

  // render current project users
  const renderCurrentProjectUser = useMemo(() => {
    return (
      currentProject?.users &&
      currentProject?.users
        ?.filter((item) => item?.role !== USER_ROLE.CLIENT)
        ?.map((item) => {
          return (
            !isEmpty(item?.id) && (
              <Tooltip placement='bottom' title={item?.name} maxWidth={'max-content'}>
                <Profileborder key={item?.id}>
                  <Avatar
                    imgSrc={item?.profile_photo || ''}
                    name={item?.name ? item?.name : 'U N'}
                    size={30}
                    isThinBorder={true}
                  />
                </Profileborder>
              </Tooltip>
            )
          );
        })
    );
  }, [currentProject?.users]);

  // render current project client data
  const renderCurrentProjectClient = useMemo(() => {
    return (
      currentProject?.users &&
      currentProject?.users?.filter((item) => item?.role === USER_ROLE.CLIENT)?.length > 0 && (
        <Profilegroup>
          {currentProject?.users
            ?.filter((item) => item?.role === USER_ROLE.CLIENT)
            ?.map((item) => {
              return (
                <Avatar
                  imgSrc={item?.profile_photo || ''}
                  name={item?.name ? item?.name : 'U N'}
                  size={30}
                  isThinBorder={true}
                  key={item?.id}
                />
              );
            })}
        </Profilegroup>
      )
    );
  }, [currentProject?.users]);

  // render milestone list
  const renderMilestoneList = useMemo(() => {
    return milestoneList?.map((milestone) => {
      const completedTaskList = milestone?.tasks?.filter((item) => item?.status);
      return (
        <Div key={milestone?.id}>
          <Leftdiv>
            <CircularProgress progressPercentage={Number(milestone?.progress)} status={milestone?.status} />
            <Subdiv>
              <p>
                {milestone?.milestoneName}
                <span>
                  ({completedTaskList?.length}/{milestone?.tasks?.length})
                </span>
              </p>
            </Subdiv>
          </Leftdiv>
          <Rightdiv>
            <ul>
              {milestone?.users?.map((userProfile) => {
                return (
                  <li key={userProfile?.id}>
                    <AvatarImage>
                      <Avatar
                        size={20}
                        imgSrc={userProfile?.profile_photo || ''}
                        name={userProfile?.name || ''}
                        isMilestoneBorder={true}
                      />
                    </AvatarImage>
                  </li>
                );
              })}
            </ul>
          </Rightdiv>
        </Div>
      );
    });
  }, [milestoneList]);

  //Handle link menu click
  const handleLinkMenuClick = useCallback(
    async (key: number, file: ProjectFileData) => {
      if (key === MENU_OPTIONS.EDIT) {
        setItemData(file);
        setIsOpen(true);
      } else if (key === MENU_OPTIONS.DELETE) {
        setItemData(file);
        setDeleteModelOpen(true);
      } else if (key === MENU_OPTIONS.COPYLINK) {
        if (!file.url) return;
        await copy(file.url);
        dispatch(setSuccessMessage('Link copied successfully!'));
      }
    },
    [dispatch]
  );

  //render dropdown action list
  const renderActionListDropdown = useCallback(
    (file: ProjectResourceDetailInterface) => {
      const fileData: ProjectFileData = {
        id: file['_id'],
        name: file.Label || '',
        url: file.Reference || '',
        uploadFileType: file.UploadFileType || FILE_TYPES.UPLOAD
      };
      return menuItems.map((item) => (
        <DropdownItem
          label={item.label}
          key={item?.key}
          tone={item.label === 'Delete' ? 'critical' : 'primary'}
          onClick={() => handleLinkMenuClick(item?.key, fileData)}
        />
      ));
    },
    [handleLinkMenuClick]
  );

  const onClickFile = useCallback(
    async (item: ProjectFileData) => {
      if (item.type && item.type === RESOURCE_TYPE.LINK) {
        const url = new URL(item?.url!);
        window.open(url, '_blank')?.focus();
        return;
      }
      if (item?.uploadFileType === FILE_TYPES.UPLOAD) {
        history.push(`/projects/details/${currentProject?.id}/files/file-view/${item?.id}`);
      } else if (
        item?.uploadFileType === FILE_TYPES.DRIVE ||
        item?.uploadFileType === FILE_TYPES.DROPBOX ||
        item?.uploadFileType === FILE_TYPES.ONEDRIVE ||
        item?.uploadFileType === FILE_TYPES.FIGMA ||
        item?.uploadFileType === FILE_TYPES.NOTION
      ) {
        try {
          const url = new URL(item?.url!);
          window.open(url, '_blank')?.focus();
        } catch (e) {
          captureException(e);
          dispatch(setErrorMessage('Can not open this URL file'));
        }
      } else if (item?.uploadFileType === FILE_TYPES.DOCS || item?.uploadFileType === FILE_TYPES.WHITEBOARD) {
        if (!item?.url) return;
        await dispatch(getDocumentData(item?.id));
        history.push(item?.url);
      }
    },
    [currentProject?.id, dispatch, history]
  );

  //Handle file menu click
  const handleFileMenuClick = useCallback(
    (key: number, file: ProjectFileData) => {
      setItemData(file);
      if (key === FILES_MENU_OPTIONS.RENAME) {
        setIsFileModelOpen(true);
      } else if (key === FILES_MENU_OPTIONS.DELETE) {
        setDeleteModelOpen(true);
      } else if (key === FILES_MENU_OPTIONS.VIEW) {
        onClickFile(file);
      }
    },
    [onClickFile]
  );

  //render dropdown for file action list
  const renderFileMenuDropdown = useCallback(
    (file: ProjectResourceDetailInterface) => {
      const fileData: ProjectFileData = {
        id: file['_id'],
        name: file.Label || '',
        url: file.Reference || '',
        uploadFileType: file.UploadFileType || FILE_TYPES.UPLOAD
      };
      return fileMenuItems.map((item) => (
        <DropdownItem
          label={item.label}
          key={item?.key}
          tone={item.label === 'Delete' ? 'critical' : 'primary'}
          onClick={() => handleFileMenuClick(item?.key, fileData)}
        />
      ));
    },
    [handleFileMenuClick]
  );

  const closeDeleteModel = useCallback(() => {
    setDeleteModelOpen(false);
    setItemData(null);
  }, []);

  const onDeleteResource = useCallback(async () => {
    try {
      if (!itemData?.id) return;
      setDeleteLoading(true);
      const response = await dispatch(deleteProjectResource(currentProject?.id, itemData?.id));
      if (response) {
        currentProject?.removeResource(itemData?.id);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setDeleteLoading(false);
      closeDeleteModel();
    }
  }, [closeDeleteModel, currentProject, dispatch, itemData?.id]);

  const renderLinks = useMemo(() => {
    return (
      currentProject?.resources &&
      currentProject?.resources?.length > 0 &&
      currentProject?.resources?.map((item) => {
        const extension = extensionResource(item).toLowerCase();
        const fileData: ProjectFileData = {
          id: item['_id'],
          name: item.Label || '',
          url: item.Reference || '',
          uploadFileType: item.UploadFileType || FILE_TYPES.UPLOAD,
          type: item.Type
        };
        return (
          <AddedLinkBox onClick={() => onClickFile(fileData)}>
            {item.Type === RESOURCE_TYPE.LINK ? (
              <LinkIcon>
                <SVGIcon name='copy-link-icon' width='16' height='16' viewBox='0 0 18 18' fill='var(--text-primary)' />
              </LinkIcon>
            ) : (!item?.UploadFileType || item?.UploadFileType === FILE_TYPES.UPLOAD) &&
              !FILE_EXTENTIONS.includes(extension) ? (
              <FileSVGIcon name={'blank'} height='16' width='16' viewBox='0 0 60 60' />
            ) : (
              <FileSVGIcon
                name={
                  !item?.UploadFileType || item?.UploadFileType === FILE_TYPES.UPLOAD
                    ? extension
                    : `icon_${item?.UploadFileType}`
                }
                height='16'
                width='16'
                viewBox='0 0 60 60'
              />
            )}
            <Text>{item?.Label}</Text>
            <Dropdown
              isMinWidth={140}
              content={
                <MoreIcon>
                  <SVGIcon name='more-icon' width='12' height='12' viewBox='0 0 18 18' className='fill-color' />
                </MoreIcon>
              }
              trigger='click'>
              {item?.Type === RESOURCE_TYPE.LINK ? renderActionListDropdown(item) : renderFileMenuDropdown(item)}
            </Dropdown>
          </AddedLinkBox>
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProject?.resources,
    currentProject?.resources?.length,
    onClickFile,
    renderActionListDropdown,
    renderFileMenuDropdown
  ]);

  if (selectFile) {
    return (
      <RenderExistingFile
        setAttachment={() => setAttachment(false)}
        setSelectFile={(value: boolean) => setSelectFile(value)}
        onSelectFile={onSelectFile}
        isProjectOverview
      />
    );
  }

  return (
    <>
      <AppLayout isOverflowHidden>
        <Accounting />
        <WrapperDiv className='project-overview'>
          <Group>
            <Podcastdiv>
              <Top>
                <Heading>{currentProject?.name}</Heading>
              </Top>
              <Profiles>
                {renderCurrentProjectUser}
                {renderCurrentProjectClient}
                {(workspace?.isAdmin || workspace?.isOwner) && (
                  <Button
                    title='Setup People '
                    secondary={true}
                    onClick={() => setMainModel(true)}
                    className='button'
                    modelbtn={true}
                  />
                )}
              </Profiles>
            </Podcastdiv>
            <ResourcesSection>
              <ResourceTitle>Resources</ResourceTitle>
              <ResourcesFileSection>
                {renderLinks}
                <Dropdown
                  isMinWidth={150}
                  content={
                    <ResourcesDrodpownHeading>
                      <SVGIcon
                        name='plus-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 18 18'
                        fill='var(--text-secondary)'
                      />
                      {currentProject?.resources && currentProject?.resources?.length === 0 && <p>File or link</p>}
                    </ResourcesDrodpownHeading>
                  }
                  trigger='click'>
                  <>
                    <DropdownItem
                      label='Upload file'
                      iconName='upload-icon'
                      iconSize={16}
                      iconTone='fill'
                      onClick={handleFileInput}
                    />
                    <DropdownItem
                      label='Select file'
                      iconName='filescreen-dropdown-file-icon'
                      iconSize={16}
                      iconTone='fill'
                      onClick={attachmentPage}
                    />
                    <DropdownItem
                      label='Add link'
                      iconName='copy-link-icon'
                      iconSize={16}
                      iconTone='fill'
                      onClick={openModel}
                    />
                  </>
                </Dropdown>
              </ResourcesFileSection>
            </ResourcesSection>
            <input
              multiple={false}
              type='file'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={onFileUpload}
            />
            <ModalCustom open={isFileModalOpen} onClose={closeFileModel} width={462}>
              <FilesModal
                onClose={closeFileModel}
                itemData={itemData}
                modalData={LINK_FILE_DATA.FOLDER}
                isProjectResource
              />
            </ModalCustom>
            <ModalCustom open={isOpen} onClose={cancelModel} width={462}>
              <LinkFileModal onClose={cancelModel} modalData={LINK_FILE_DATA.RESOURCES} itemData={itemData} />
            </ModalCustom>

            {/* upload file loading state */}
            {uploadFilePercent > 0 && uploadFileDetail && (
              <FileUploadCard
                file={uploadFileDetail?.[0]}
                progress={uploadFilePercent}
                fileSize={formatBytes(uploadFileDetail?.[0]?.size || 0)}
              />
            )}

            <Taskdiv>
              <Taskcard
                heading={'Completed tasks'}
                count={tasksCount?.completedTasks}
                iconname={'overview-task-icon'}
              />
              <Taskcard
                heading={'Incomplete tasks'}
                count={tasksCount?.inCompletedTasks}
                iconname={'overview-incompletetask-icon'}
              />
              <Taskcard
                heading={'Overdue tasks'}
                count={tasksCount?.overDueTasks}
                iconname={'overview-overduetask-icon'}
              />
            </Taskdiv>
            {(workspace?.isAdmin || workspace?.isOwner) && (
              <Graphbox>
                <Header>
                  <p>Time-logs</p>
                  {(tasksCount.completedTasks > 0 ||
                    tasksCount.inCompletedTasks > 0 ||
                    tasksCount.overDueTasks > 0) && (
                    <Dropdowns>
                      <Dropdown
                        isMinWidth={152}
                        content={
                          <Status onClick={handleDropdownOpen}>
                            <p>{selectedTimelogFilter}</p>
                            <SVGIcon
                              name='dropdown-icon'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              className={openDropdown ? 'dropdown-icon fill-color' : 'fill-color'}
                            />
                          </Status>
                        }
                        trigger='click'>
                        <Viewdropdown>{renderedTimelogItems}</Viewdropdown>
                      </Dropdown>
                    </Dropdowns>
                  )}
                </Header>

                {/* Chart */}
                {!chartLoading ? (
                  <LineChart
                    isOverviewPage={true}
                    projectWiseChartData={timeChartData}
                    onChangeChartType={function (value: number): Promise<void> {
                      throw new Error('Function not implemented.');
                    }}
                  />
                ) : (
                  <Loadingimage>
                    <ReportTimelineLoading />
                  </Loadingimage>
                )}
              </Graphbox>
            )}
            {currentProject?.isMilestone && (
              <Milestonebox>
                <p>Milestones</p>

                {!loading && milestoneList?.length === 0 && (
                  <FileEmptystate isMilestonebox={true}>
                    <EmptyState
                      image={getImageSource()}
                      header='No milestones added yet'
                      name='New Milestone'
                      onButtonClick={onOpenMilestoneModal}
                      isgap={16}
                      isOverviewEmpty={true}
                    />
                  </FileEmptystate>
                )}

                {!loading && milestoneList?.length > 0 && <Milestone>{renderMilestoneList}</Milestone>}
              </Milestonebox>
            )}
          </Group>
          {attachment && (
            <RenderExistingFile
              setAttachment={() => setAttachment(false)}
              isProjectOverview={true}
              onSelectFile={onSelectFile}
            />
          )}
        </WrapperDiv>
      </AppLayout>
      <InviteMemberModal
        isOpen={isMainModel}
        onClose={closeInviteModal}
        projectName={currentProject?.name || 'Unnamed Project'}
        renderData={getRenderData()}
        query={query}
        onChangeSearch={onChangeSearch}
        customersUsers={clientList}
        isWorkspaceUser={isWorkspaceUser}
        onClickWorkspaceButton={onClickWorkspaceButton}
        onClickClientButton={onClickClientButton}
        currentProjectUsers={currentProject?.users}
        onClickMemberBox={onClickMemberBox}
        onSubModel={onSubModel}
      />
      <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={334}>
        <Deletemodal
          onClose={closeDeleteModel}
          onOk={onDeleteResource}
          loading={deleteLoading}
          modaltitle={`Delete ${itemData?.uploadFileType ? 'File' : 'Link'}`}
          description={`Are you sure you want to delete this ${!itemData?.uploadFileType ? 'Link?' : 'File?'}`}
        />
      </ModalCustom>
      <ModalCustom open={isSubModel} onClose={onCloseSubModal} width={400}>
        <Inviteteamsmodal
          onCancel={onCloseSubModal}
          project_id={params?.id}
          isTextVisible={true}
          projectName={currentProject?.name}
          workspace_id={workspace?.id || ''}
        />
      </ModalCustom>
      <ModalCustom width={462} open={isAddMilestoneModalOpen} onClose={onCloseMilestoneModal}>
        <MilestoneModal onClose={onCloseMilestoneModal} projectId={params?.id} />
      </ModalCustom>
    </>
  );
};

export default observer(Overview);
