import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import { Bottomdata, CutstomItemDiv } from '../styles/style';
import {
  Header,
  Contentheading,
  Rightcontent,
  Leftcontent,
  Container,
  Monthpicker,
  Text,
  Datedropbox,
  Button,
  DropdownButton,
  DateSelection,
  SvgIconDiv,
  HeaderLeft,
  BackIcon,
  ItemDiv
} from '../styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Tableheader from '../../../component/tableheader/tableheader';
import LineChart from '../../../component/chart/chart';
import { useDispatch, useSelector } from 'react-redux';
import { loadTimeLogs } from '../../../services/timeTrackingServices';
import { ReportFilterInterface } from '../../../interfaces/TimeTrackingInterface';
import { calculateEndTime, floatToHHMM, getMonthRange, getUTCDate, isEmpty } from '../../../helpers/common';
import { getProjectList } from '../../../services/projectServices';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';
import {
  TIME_DATE_SELECTED_TEXT,
  TIME_REPORT_CHART_TYPE_ID,
  TIME_REPORT_DATA_FILTER_ID,
  TIME_REPORT_VIEW_TYPE_ID
} from '../../../global/constants';
import {
  WorkSummaryInterface,
  TimeLogInterface,
  WorkSummaryItemInterface,
  TableData,
  SelectionRangeInterface,
  ActivityDataInterface,
  ChartDataInterface,
  ChartDetailsInterface
} from '../../../interfaces/TimeReportInterface';
import { updateReportFilterDropdown } from '../../../actions/timeTrackingActions';
import { useHistory } from 'react-router-dom';
import { captureException } from '../../../services/logService';
import { getUserPreferenceFieldData, updateUserPreference } from '../../../helpers/firebaseHelper';
import { ReportTimeFilterInterface, SelectRangeInterface } from '../../../interfaces/ReportInterface';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import ReportTimelineLoading from '../../../component/loading/reporttimelineLoading';
import ReportDurationDropdown from '../../../component/dropdowns/reportdurationdropdown/reportdurationdropdown';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

const Times: React.FC = () => {
  //Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, project: projectSelector, timeTracking: timeTrackingSelector } = stateSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { timeTrackingReportData, reportFilter } = timeTrackingSelector;
  const { list } = projectSelector;
  //States
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isMemberDropdownOpen, setIsMemberDropdownOpen] = useState(false);
  const [projectWiseChartData, setProjectWiseChartData] = useState<ChartDataInterface>({ data: [], x_axis_data: [] });
  const [timeSheetData, setTimeSheetData] = useState<TableData>({});
  const [activityData, setActivityData] = useState<ActivityDataInterface[]>([]);
  const [workSummaryData, setWorkSummaryData] = useState<WorkSummaryInterface[]>([]);
  const [searchProject, setSearchProject] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [time_filter, set_time_filter] = useState<ReportTimeFilterInterface>();
  const [filterProjectList, setFilterProjectList] = useState<ProjectDetailInterface[]>([]);
  const [filterUserList, setFilterUserList] = useState<UsersWorkspaceInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  //Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  const loadDataBasedOnFilter = useCallback(
    (
      logData: TimeLogInterface[],
      projects: ProjectDetailInterface[],
      users: UsersWorkspaceInterface[],
      selectionRange: SelectionRangeInterface,
      reportFilter: ReportFilterInterface,
      projectList?: ProjectDetailInterface[]
    ) => {
      try {
        if (!projectList) projectList = list;
        setFilterLoading(true);
        const selectedProjectIds = projects?.map((x) => x?.id);
        const selectedUsersIds = users?.map((x) => x?.id);
        logData = logData?.filter(
          (item) =>
            selectedProjectIds?.includes(item?.projectId) &&
            selectedUsersIds?.includes(item?.createdBy) &&
            moment(item?.date) >= moment(selectionRange.startDate) &&
            moment(item?.date) <= moment(selectionRange.endDate).add(1, 'day')
        );
        // WORKSUMMARY
        if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_PROJECT
        ) {
          let project_work_summary: WorkSummaryInterface[] = [];
          projects?.forEach((item) => {
            let total_time = 0;
            const project = projectList?.find((project) => project?.id === item?.id);
            let items: WorkSummaryItemInterface[] = [];
            users?.forEach((ele) => {
              const user = workspace?.users?.find((user) => user?.id === ele?.id);
              const memberTask = logData?.filter((x) => x?.createdBy === ele?.id && x?.projectId === item?.id);
              const memberHour = memberTask.reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              if (memberHour > 0) {
                total_time = total_time + memberHour;
                items.push({
                  id: ele?.id,
                  name: user?.name || '',
                  time: floatToHHMM(memberHour),
                  amount: 0,
                  currency: 'USD'
                });
              }
            });
            if (total_time > 0) {
              let data: WorkSummaryInterface = {
                id: item?.id,
                name: project?.name || '',
                amount: 0,
                time: floatToHHMM(total_time),
                items
              };
              project_work_summary?.push(data);
            }
          });
          setWorkSummaryData(project_work_summary);
        } else if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_MEMBER
        ) {
          let member_work_summary: WorkSummaryInterface[] = [];
          users?.forEach((item) => {
            let total_time = 0;
            let items: WorkSummaryItemInterface[] = [];
            const user = workspace?.users?.find((user) => user?.id === item?.id);
            projects?.forEach((ele) => {
              const project = list?.find((project) => project?.id === ele?.id);
              const projectLogs = logData?.filter((x) => x?.projectId === ele?.id && x?.createdBy === item?.id);
              const projectHour = projectLogs.reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              if (projectHour > 0) {
                total_time = total_time + projectHour;
                items.push({
                  id: ele?.id,
                  name: project?.name || '',
                  time: floatToHHMM(projectHour),
                  amount: 0,
                  currency: 'USD'
                });
              }
            });
            if (total_time > 0) {
              let data = {
                id: item?.id,
                name: user?.name || '',
                amount: 0,
                time: floatToHHMM(total_time),
                items
              };
              member_work_summary?.push(data);
            }
          });
          setWorkSummaryData(member_work_summary);
        } else if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_ACTIVITY
        ) {
          let activity_work_summary: WorkSummaryInterface[] = [];
          users?.forEach((item) => {
            let total_time = 0;
            const user = workspace?.users?.find((user) => user?.id === item?.id);
            let items: WorkSummaryItemInterface[] = [];
            const userTimeLogs = logData?.filter((x) => x?.createdBy === item?.id);

            const groupedData = userTimeLogs.reduce((acc: any, obj: TimeLogInterface) => {
              const key = obj.taskId;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});
            Object.keys(groupedData).forEach((taskId) => {
              const totalHour = groupedData[taskId].reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              if (totalHour > 0) {
                total_time = total_time + totalHour;
                const obj = {
                  id: taskId,
                  name: groupedData[taskId][0]?.taskName,
                  time: floatToHHMM(totalHour),
                  amount: 0,
                  currency: 'USD'
                };
                items?.push(obj);
              }
            });
            if (total_time > 0) {
              let data = { id: item?.id, name: user?.name || '', amount: 0, time: floatToHHMM(total_time), items };
              activity_work_summary?.push(data);
            }
          });
          setWorkSummaryData(activity_work_summary);
        }
        // TIMESHEET
        else if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.TIMESHEET &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_PROJECT
        ) {
          //column
          let project_time_sheet_column: { Header: string; accessor: string }[] = [
            { Header: 'Projects Name', accessor: 'name' }
          ];
          let startDateRef = moment(selectionRange.startDate);
          while (startDateRef.isBefore(moment(selectionRange.endDate).add(1, 'day'), 'day')) {
            project_time_sheet_column.push({
              Header: startDateRef.format('MMM DD'),
              accessor: `date${startDateRef.format('DD_MM_YYYY')}`
            });
            startDateRef.add(1, 'day');
          }
          project_time_sheet_column.push({ Header: 'Total', accessor: 'total_time' });
          //data
          let project_time_sheet: any = [];
          let totalObj: any = { name: 'Total' };
          projects?.forEach((item) => {
            let total_time = 0;
            const project = list?.find((project) => project?.id === item?.id);
            let data: any = { name: project?.name };
            let startDateRef = moment(selectionRange.startDate);
            let endDateRef = moment(selectionRange.endDate).add(1, 'day');
            while (startDateRef.isBefore(endDateRef, 'day')) {
              const logs = logData?.filter(
                (x) =>
                  x?.projectId === item?.id &&
                  getUTCDate(x?.date).toISOString() === getUTCDate(startDateRef).toISOString()
              );
              const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              data[`date${startDateRef.format('DD_MM_YYYY')}`] = totalHours > 0 ? floatToHHMM(totalHours) : '--';
              totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] =
                (totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] || 0) + totalHours;
              total_time = total_time + totalHours;
              startDateRef.add(1, 'day');
            }
            data.total_time_float = total_time;
            data.total_time = total_time > 0 ? floatToHHMM(total_time) : '--';
            totalObj['total_time'] = (totalObj['total_time'] || 0) + total_time;
            project_time_sheet?.push(data);
          });
          project_time_sheet = project_time_sheet.toSorted((a: any, b: any) => b.total_time_float - a.total_time_float);
          Object.keys(totalObj).forEach((key) => {
            if (key !== 'name') {
              totalObj[key] = totalObj[key] > 0 ? floatToHHMM(totalObj[key]) : '--';
            }
          });
          if (project_time_sheet?.length > 0) project_time_sheet.push(totalObj);
          setTimeSheetData({ column: project_time_sheet_column, data: project_time_sheet });
        } else if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.TIMESHEET &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_MEMBER
        ) {
          //column
          let member_time_sheet_column: { Header: string; accessor: string }[] = [
            { Header: 'Member Name', accessor: 'name' }
          ];
          let startDateRef = moment(selectionRange.startDate);
          while (startDateRef.isBefore(moment(selectionRange.endDate).add(1, 'day'), 'day')) {
            member_time_sheet_column.push({
              Header: startDateRef.format('MMM DD'),
              accessor: `date${startDateRef.format('DD_MM_YYYY')}`
            });
            startDateRef.add(1, 'day');
          }
          member_time_sheet_column.push({ Header: 'Total', accessor: 'total_time' });
          //data
          let member_time_sheet: any = [];
          let totalObj: any = { name: 'Total' };
          users?.forEach((item) => {
            let total_time = 0;
            const user = workspace?.users?.find((user) => user?.id === item?.id);
            let data: any = { name: user?.name };
            let startDateRef = moment(selectionRange.startDate);
            let endDateRef = moment(selectionRange.endDate).add(1, 'day');
            while (startDateRef.isBefore(endDateRef, 'day')) {
              const logs = logData?.filter(
                (x) =>
                  x?.createdBy === item?.id &&
                  getUTCDate(x?.date).toISOString() === getUTCDate(startDateRef).toISOString()
              );
              const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              data[`date${startDateRef.format('DD_MM_YYYY')}`] = totalHours > 0 ? floatToHHMM(totalHours) : '--';
              totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] =
                (totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] || 0) + totalHours;
              total_time = total_time + totalHours;
              startDateRef.add(1, 'day');
            }
            data.total_time_float = total_time;
            data.total_time = total_time > 0 ? floatToHHMM(total_time) : '--';
            totalObj['total_time'] = (totalObj['total_time'] || 0) + total_time;
            member_time_sheet?.push(data);
          });
          member_time_sheet = member_time_sheet.toSorted((a: any, b: any) => b.total_time_float - a.total_time_float);
          Object.keys(totalObj).forEach((key) => {
            if (key !== 'name') {
              totalObj[key] = totalObj[key] > 0 ? floatToHHMM(totalObj[key]) : '--';
            }
          });
          if (member_time_sheet?.length > 0) member_time_sheet.push(totalObj);
          setTimeSheetData({ column: member_time_sheet_column, data: member_time_sheet });
        } else if (
          reportFilter?.selectedDataType === TIME_REPORT_DATA_FILTER_ID.TIMESHEET &&
          reportFilter?.timeViewType === TIME_REPORT_VIEW_TYPE_ID.BY_ACTIVITY
        ) {
          //column
          let activity_time_sheet_column: { Header: string; accessor: string }[] = [
            { Header: 'Activity Name', accessor: 'name' }
          ];
          let startDateRef = moment(selectionRange.startDate);
          while (startDateRef.isBefore(moment(selectionRange.endDate).add(1, 'day'), 'day')) {
            activity_time_sheet_column.push({
              Header: startDateRef.format('MMM DD'),
              accessor: `date${startDateRef.format('DD_MM_YYYY')}`
            });
            startDateRef.add(1, 'day');
          }
          activity_time_sheet_column.push({ Header: 'Total', accessor: 'total_time' });
          //data
          let activity_time_sheet: ActivityDataInterface[] = [];
          const groupedData = logData.reduce((acc: any, obj: TimeLogInterface) => {
            const key = obj.taskId;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {});
          let totalObj: any = { name: 'Total' };
          Object.keys(groupedData).forEach((taskId) => {
            let objData: any = { name: groupedData[taskId][0]?.taskName };
            let total_time = 0;
            let startDateRef = moment(selectionRange.startDate);
            let endDateRef = moment(selectionRange.endDate).add(1, 'day');
            while (startDateRef.isBefore(endDateRef, 'day')) {
              const logs = groupedData[taskId]?.filter(
                (x: TimeLogInterface) => moment(x?.date).format('YYYY-MM-DD') === startDateRef.format('YYYY-MM-DD')
              );
              const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
                return acc + obj.hours;
              }, 0);
              objData[`date${startDateRef.format('DD_MM_YYYY')}`] = totalHours > 0 ? floatToHHMM(totalHours) : '--';
              totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] =
                (totalObj[`date${startDateRef.format('DD_MM_YYYY')}`] || 0) + totalHours;
              total_time = total_time + totalHours;
              startDateRef.add(1, 'day');
            }
            objData.total_time_float = total_time;
            objData.total_time = total_time > 0 ? floatToHHMM(total_time) : '--';
            totalObj['total_time'] = (totalObj['total_time'] || 0) + total_time;
            activity_time_sheet.push(objData);
          });
          activity_time_sheet = activity_time_sheet.toSorted(
            (a: any, b: any) => b.total_time_float - a.total_time_float
          );
          Object.keys(totalObj).forEach((key) => {
            if (key !== 'name') {
              totalObj[key] = totalObj[key] > 0 ? floatToHHMM(totalObj[key]) : '--';
            }
          });
          if (activity_time_sheet?.length > 0) activity_time_sheet.push(totalObj);
          setTimeSheetData({ column: activity_time_sheet_column, data: activity_time_sheet });
        }
        // ACTIVITYLOG

        let activityData: ActivityDataInterface[] = [];
        logData?.forEach((item) => {
          if (item?.history?.length > 0) {
            const maxTimerEndedAt = Math.max(
              ...item.history.map((history) => new Date(history.TimerEndedAt).getTime())
            );
            const maxTimerEndedAtString = new Date(maxTimerEndedAt).toISOString();

            const minTimerStartedAt = Math.min(
              ...item.history
                .map((history) => new Date(history.TimerStartedAt || new Date()).getTime())
                ?.filter((x) => !isEmpty(x))
            );
            const minTimerStartedAtString = new Date(minTimerStartedAt).toISOString();

            activityData.push({
              date: moment(item?.date).format('MMM DD'),
              time: `${moment(minTimerStartedAtString).format('h:mma')} - ${moment(maxTimerEndedAtString).format(
                'h:mma'
              )}`,
              startTime: moment(minTimerStartedAtString).format('YYYY-MM-DD HH:mm:ss'),
              endTime: moment(calculateEndTime(minTimerStartedAtString, item?.hours)).format('YYYY-MM-DD HH:mm:ss'),
              activityName: item?.taskName,
              member: item?.userName,
              projectName: item?.projectName,
              duration: floatToHHMM(item?.hours),
              screenshots: isEmpty(item?.screenshots) ? 0 : item?.screenshots?.length,
              screenshotImage: isEmpty(item?.screenshots) ? '' : item?.screenshots[0]?.Url,
              screenshotImageList: item?.screenshots || []
            });
            // });
          } else {
            if (item?.hours > 0)
              activityData.push({
                date: moment(item?.date).format('MMM DD'),
                time: `${moment(item?.createdOn).format('h:mma')} - ${moment(item?.createdOn)
                  .add(item?.hours, 'hours')
                  .format('h:mma')}`,
                startTime: moment(item?.createdOn).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(calculateEndTime(item?.createdOn, item?.hours)).format('YYYY-MM-DD HH:mm:ss'),
                activityName: item?.taskName,
                member: item?.userName,
                projectName: item?.projectName,
                duration: floatToHHMM(item?.hours),
                screenshots: isEmpty(item?.screenshots) ? 0 : item?.screenshots?.length,
                screenshotImage: isEmpty(item?.screenshots) ? '' : item?.screenshots[0]?.Url,
                screenshotImageList: item?.screenshots || []
              });
          }
        });
        setActivityData(activityData);
      } catch (error) {
        console.log('error', error);
        setFilterLoading(false);
      } finally {
        setFilterLoading(false);
      }
      // }
    },
    [list, workspace?.users]
  );

  const applyFilter = useCallback(
    async (
      projects: ProjectDetailInterface[],
      users: UsersWorkspaceInterface[],
      selectionRange: SelectRangeInterface,
      filterObj?: ReportFilterInterface,
      timeReportData?: TimeLogInterface[],
      projectList?: ProjectDetailInterface[]
    ) => {
      const selectedProjects = projects?.filter((x) => x?.checked);
      const selectedUsers = users?.filter((x) => x?.checked);
      if (!filterObj) filterObj = reportFilter;
      if (!projectList) projectList = list;
      const selectedProjectIds = selectedProjects?.map((x) => x?.id);
      const selectedUsersIds = selectedUsers?.map((x) => x?.id);
      if (!timeReportData) timeReportData = timeTrackingReportData;
      let timeLogData: TimeLogInterface[] = JSON.parse(JSON.stringify(timeReportData));
      const startDate = moment(selectionRange.startDate);
      const endDate = moment(selectionRange.endDate).add(1, 'day');
      timeLogData = timeLogData?.filter(
        (item) =>
          selectedProjectIds?.includes(item?.projectId) &&
          selectedUsersIds?.includes(item?.createdBy) &&
          moment(item?.date) >= startDate &&
          moment(item?.date) <= endDate
      );
      let chart_data: ChartDetailsInterface[] = [];

      loadDataBasedOnFilter(timeLogData, selectedProjects, selectedUsers, selectionRange, filterObj, projectList);

      if (filterObj?.chartType === TIME_REPORT_CHART_TYPE_ID.PROJECT) {
        selectedProjects?.forEach((item) => {
          const project = projectList?.find((project) => project?.id === item?.id);
          let data = [];
          let total_time = 0;
          let startDateRef = moment(selectionRange.startDate);
          while (startDateRef.isBefore(endDate, 'day')) {
            const logs = timeLogData?.filter(
              (x) =>
                x?.projectId === item?.id && moment(x?.date).format('YYYY-MM-DD') === startDateRef.format('YYYY-MM-DD')
            );
            const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
              return acc + obj.hours;
            }, 0);
            total_time = total_time + totalHours;
            data?.push(floatToHHMM(totalHours));
            startDateRef.add(1, 'day');
          }

          const dataObj = { id: item?.id, name: project?.name || '', data: data };
          chart_data.push(dataObj);
        });
      } else {
        selectedUsers?.forEach((item) => {
          let data = [];
          let total_time = 0;
          const user = workspace?.users?.find((user) => user?.id === item?.id);
          let startDateRef = moment(selectionRange.startDate);
          while (startDateRef.isBefore(endDate, 'day')) {
            const logs = timeLogData?.filter(
              (x) =>
                x?.createdBy === item?.id && moment(x?.date).format('YYYY-MM-DD') === startDateRef.format('YYYY-MM-DD')
            );
            const totalHours = logs.reduce((acc: number, obj: { hours: number }) => {
              return acc + obj.hours;
            }, 0);
            total_time = total_time + totalHours;
            data?.push(floatToHHMM(totalHours));
            startDateRef.add(1, 'day');
          }

          const dataObj = { id: item?.id, name: user?.name || '', data: data };
          chart_data.push(dataObj);
        });
      }
      let startDateRef = moment(selectionRange.startDate);
      let x_axis_data: string[] = [];
      while (startDateRef.isBefore(endDate, 'day')) {
        x_axis_data.push(startDateRef.format('DD MMM'));
        startDateRef.add(1, 'day');
      }
      setProjectWiseChartData({ data: chart_data, x_axis_data });
    },
    [reportFilter, list, timeTrackingReportData, loadDataBasedOnFilter, workspace?.users]
  );

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        const result = await getUserPreferenceFieldData(`time_report_filter_${workspace_id}`);
        const monthRange = getMonthRange(result?.selected_range?.startDate, result?.selected_range?.endDate);
        const logList = await dispatch(loadTimeLogs(workspace_id, monthRange));
        const projectsResponse = await dispatch(getProjectList());
        const updatedProjectsResponse = projectsResponse?.map((item: ProjectDetailInterface) => {
          return { id: item?.id, checked: true };
        });
        const usersList = workspace?.users?.map((item: UsersWorkspaceInterface) => {
          return { id: item?.id, checked: true };
        });
        const hasNewProjects =
          !result?.project_list ||
          updatedProjectsResponse.some(
            (project: { id: string }) => !result.project_list.some((p: { id: string }) => p.id === project?.id)
          );

        const hasNewUser =
          !result?.user_list ||
          usersList.some((user: { id: string }) => !result.user_list.some((u: { id: string }) => u.id === user?.id));
        const data = {
          ...(result || {}),
          project_list: hasNewProjects ? updatedProjectsResponse : result?.project_list,

          user_list: hasNewUser ? usersList : result?.user_list,

          selected_text: !result?.selected_text ? 'Custom' : result?.selected_text,
          selected_range: !result?.selected_range
            ? {
                startDate: new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]')).toISOString(),
                endDate: new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]')).toISOString(),
                key: 'selection'
              }
            : {
                startDate: new Date(result?.selected_range?.startDate).toISOString(),
                endDate: new Date(result?.selected_range?.endDate).toISOString(),
                key: result?.selected_range?.key
              },
          selected_all_user: isEmpty(result?.selected_all_user) ? true : result?.selected_all_user,
          selected_all_project: isEmpty(result?.selected_all_project) ? true : result?.selected_all_project
        };
        set_time_filter(data);
        setFilterProjectList(hasNewProjects ? projectsResponse : result?.project_list);
        setFilterUserList(hasNewUser ? usersList : result?.user_list);
        await applyFilter(
          !data?.project_list ? projectsResponse : data?.project_list,
          !data?.user_list ? usersList : data?.user_list,
          !data?.selected_range
            ? {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
              }
            : {
                startDate: new Date(data?.selected_range?.startDate),
                endDate: new Date(data?.selected_range?.endDate),
                key: data?.selected_range?.key
              },
          undefined,
          logList,
          projectsResponse
        );
        await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [applyFilter, dispatch, workspace, workspace_id]);

  useEffect(() => {
    if (!isEmpty(workspace_id) && !workspace?.isAdmin) return history.push('/');
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, workspace?.isAdmin, workspace_id]);

  const onChangeReportFilter = useCallback(
    async (propsName: string, value: number) => {
      dispatch(updateReportFilterDropdown({ propsName, value }));
      const filterClone = JSON.parse(JSON.stringify(reportFilter));
      filterClone[propsName] = value;
      if (time_filter?.selected_range) {
        loadDataBasedOnFilter(
          timeTrackingReportData,
          time_filter?.project_list?.filter((x) => x?.checked) || [],
          time_filter?.user_list?.filter((x) => x?.checked) || [],
          time_filter?.selected_range,
          filterClone
        );
      }
    },
    [dispatch, reportFilter, loadDataBasedOnFilter, timeTrackingReportData, time_filter]
  );

  //project list actions
  const onChangeSearchProject = useCallback(
    async (value: string) => {
      setSearchProject(value);
      let timeout: NodeJS.Timeout | undefined;

      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            time_filter?.project_list?.filter((item: { id: string }) =>
              list
                ?.find((project) => project?.id === item?.id)
                ?.name?.toLowerCase()
                .includes(value?.toLowerCase())
            ) || [];
          setFilterProjectList(newList);
        }, 300);
      } else {
        if (time_filter?.project_list) setFilterProjectList(time_filter?.project_list);
      }
    },
    [list, time_filter]
  );

  const onSelectAllProjectClick = useCallback(
    async (projectList: ProjectDetailInterface[], value: boolean, users: UsersWorkspaceInterface[]) => {
      let projectListClone: ProjectDetailInterface[] = JSON.parse(JSON.stringify(projectList));
      projectListClone = projectListClone?.map((item) => {
        return { ...item, checked: value };
      });
      const data = {
        ...(time_filter || {}),
        project_list: projectListClone,
        selected_all_project: value
      };
      setFilterProjectList(projectListClone);
      if (data) set_time_filter(data);
      if (time_filter?.selected_range) applyFilter(projectListClone, users, time_filter?.selected_range);
      await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
    },
    [applyFilter, time_filter, workspace?.id]
  );

  const onCheckProject = useCallback(
    async (
      allProjectList: ProjectDetailInterface[],
      filteredList: ProjectDetailInterface[],
      projectId: string,
      value: boolean,
      users: UsersWorkspaceInterface[]
    ) => {
      const updatedAllList = allProjectList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const uncheckedTasks = updatedAllList?.find((x) => !x?.checked);
      let newValue;
      if (uncheckedTasks || updatedFilteredList?.length === 0) {
        newValue = false;
      } else {
        newValue = true;
      }

      const data = {
        ...(time_filter || {}),
        project_list: updatedAllList,
        selected_all_project: newValue
      };
      setFilterProjectList(updatedFilteredList);
      set_time_filter(data);
      if (time_filter?.selected_range) applyFilter(updatedAllList, users, time_filter?.selected_range);
      await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
    },
    [applyFilter, time_filter, workspace?.id]
  );

  //user list actions
  const onChangeSearchUser = useCallback(
    async (value: string) => {
      setSearchUser(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            time_filter?.user_list?.filter((item: { id: string }) =>
              workspace?.users
                ?.find((project) => project?.id === item?.id)
                ?.name?.toLowerCase()
                .includes(value?.toLowerCase())
            ) || [];
          setFilterUserList(newList);
        }, 300);
      } else {
        if (time_filter?.user_list) setFilterUserList(time_filter?.user_list);
      }
    },
    [time_filter?.user_list, workspace?.users]
  );

  const onSelectAllUserClick = useCallback(
    async (userList: UsersWorkspaceInterface[], value: boolean, projects: ProjectDetailInterface[]) => {
      let usersListClone: UsersWorkspaceInterface[] = JSON.parse(JSON.stringify(userList));
      usersListClone = usersListClone?.map((item) => {
        return { ...item, checked: value };
      });

      const data = {
        ...(time_filter || {}),
        user_list: usersListClone,
        selected_all_user: value
      };
      setFilterUserList(usersListClone);
      set_time_filter(data);
      if (time_filter?.selected_range) applyFilter(projects, usersListClone, time_filter?.selected_range);
      await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
    },
    [applyFilter, time_filter, workspace?.id]
  );

  const onCheckUser = useCallback(
    async (
      allUserList: UsersWorkspaceInterface[],
      filteredList: UsersWorkspaceInterface[],
      userId: string,
      value: boolean,
      projects: ProjectDetailInterface[]
    ) => {
      const updatedAllList = allUserList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const uncheckedTasks = updatedAllList?.find((x) => !x?.checked);
      let newValue;
      if (uncheckedTasks || updatedFilteredList?.length === 0) {
        newValue = false;
      } else {
        newValue = true;
      }

      const data = {
        ...(time_filter || {}),
        user_list: updatedAllList,
        selected_all_user: newValue
      };
      setFilterUserList(updatedFilteredList);
      set_time_filter(data);
      if (time_filter?.selected_range) applyFilter(projects, updatedAllList, time_filter?.selected_range);
      await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
    },
    [applyFilter, time_filter, workspace?.id]
  );

  const updateDateRangeChange = useCallback(
    async (startDate: Date, endDate: Date, selected_text: string) => {
      try {
        const selecteDates: SelectionRangeInterface = {
          startDate: startDate,
          endDate: endDate,
          key: 'selection'
        };
        setIsStatusDropdownOpen(false);
        const data: ReportTimeFilterInterface = {
          ...(time_filter || {}),
          selected_range: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            key: 'selection'
          },
          selected_text: selected_text
        };
        set_time_filter(data);
        if (time_filter?.project_list && time_filter?.user_list) {
          const monthRange = getMonthRange(data?.selected_range?.startDate, data?.selected_range?.endDate);
          const logList = await dispatch(loadTimeLogs(workspace_id, monthRange));
          applyFilter(time_filter?.project_list, time_filter?.user_list, selecteDates, undefined, logList);
        }
        // check this
        await updateUserPreference({ [`time_report_filter_${workspace_id}`]: data });
      } catch (e) {}
    },
    [applyFilter, dispatch, time_filter, workspace_id]
  );

  const openStatusDropdown = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsDateDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsMemberDropdownOpen(false);
  }, []);

  const openDateDropdown = useCallback(
    (value: boolean) => {
      if (!loading && !filterLoading) {
        setIsDateDropdownOpen(value);
        setIsStatusDropdownOpen(false);
        setIsProjectDropdownOpen(false);
        setIsMemberDropdownOpen(false);
      }
    },
    [filterLoading, loading]
  );

  const openProjectDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(value);
    setIsMemberDropdownOpen(false);
  }, []);

  const openMemberDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsMemberDropdownOpen(value);
  }, []);

  const handleSelect = useCallback(
    async (ranges: { selection: { startDate: Date; endDate: Date; key: string } }) => {
      try {
        setDataLoading(true);
        const data = {
          ...(time_filter || {}),
          selected_range: {
            startDate: new Date(ranges.selection.startDate).toISOString(),
            endDate: new Date(ranges.selection.endDate).toISOString(),
            key: ranges.selection.key
          },
          selected_text: TIME_DATE_SELECTED_TEXT.CUSTOM
        };
        set_time_filter(data);
        if (time_filter?.project_list && time_filter?.user_list) {
          const monthRange = getMonthRange(data?.selected_range?.startDate, data?.selected_range?.endDate);
          const logList = await dispatch(loadTimeLogs(workspace_id, monthRange));
          applyFilter(time_filter?.project_list, time_filter?.user_list, ranges.selection, undefined, logList);
        }
        await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
      } catch (e) {
      } finally {
        setDataLoading(false);
      }
    },
    [applyFilter, dispatch, time_filter, workspace?.id, workspace_id]
  );

  const onChangeChartType = useCallback(
    (value: number) => {
      dispatch(updateReportFilterDropdown({ propsName: 'chartType', value }));
      const filterClone = JSON.parse(JSON.stringify(reportFilter));
      filterClone['chartType'] = value;
      if (time_filter?.project_list && time_filter?.user_list && time_filter?.selected_range) {
        applyFilter(time_filter?.project_list, time_filter?.user_list, time_filter?.selected_range, filterClone);
      }
    },
    [dispatch, reportFilter, applyFilter, time_filter]
  );

  const renderProjectData = useMemo(() => {
    return (
      <Bottomdata isborderredius isLoading={loading}>
        {isEmpty(searchProject) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={time_filter?.selected_all_project}
            onClick={() =>
              onSelectAllProjectClick(
                time_filter?.project_list || [],
                !time_filter?.selected_all_project,
                time_filter?.user_list || []
              )
            }
          />
        )}
        {(isEmpty(searchProject) ? time_filter?.project_list : filterProjectList)?.map(
          (item: ProjectDetailInterface) => {
            const project = list?.find((project) => project?.id === item?.id);
            return (
              <DropdownItem
                key={item?.id}
                label={project?.name}
                isCheckBox={true}
                isChecked={item?.checked}
                onClick={(e) =>
                  onCheckProject(
                    time_filter?.project_list || [],
                    filterProjectList,
                    item?.id,
                    !item?.checked,
                    time_filter?.user_list || []
                  )
                }
              />
            );
          }
        )}
      </Bottomdata>
    );
  }, [
    loading,
    searchProject,
    time_filter?.selected_all_project,
    time_filter?.project_list,
    time_filter?.user_list,
    filterProjectList,
    onSelectAllProjectClick,
    list,
    onCheckProject
  ]);

  const renderUserData = useMemo(() => {
    return (
      <Bottomdata isLoading={loading}>
        {isEmpty(searchUser) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={time_filter?.selected_all_user}
            onClick={() =>
              onSelectAllUserClick(filterUserList, !time_filter?.selected_all_user, time_filter?.project_list || [])
            }
          />
        )}
        {(isEmpty(searchUser) ? time_filter?.user_list : filterUserList)?.map((item: UsersWorkspaceInterface) => {
          const user = workspace?.users?.find((user) => user?.id === item?.id);
          return (
            <DropdownItem
              label={user?.name}
              isCheckBox={true}
              isChecked={item?.checked}
              onClick={(e) =>
                onCheckUser(
                  time_filter?.user_list || [],
                  filterUserList,
                  item?.id,
                  !item?.checked,
                  time_filter?.project_list || []
                )
              }
            />
          );
        })}
      </Bottomdata>
    );
  }, [
    loading,
    searchUser,
    time_filter?.selected_all_user,
    time_filter?.user_list,
    time_filter?.project_list,
    filterUserList,
    onSelectAllUserClick,
    workspace?.users,
    onCheckUser
  ]);

  return (
    <>
      <AppLayout>
        <Header>
          <HeaderLeft>
            <BackIcon onClick={() => history.goBack()}>
              <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
            </BackIcon>
            Time
          </HeaderLeft>
        </Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <Dropdown
                onChangeSearch={onChangeSearchProject}
                content={
                  <DropdownButton>
                    <Button
                      placeholder='By Project'
                      disabled={loading || filterLoading}
                      onClick={() => openProjectDropdown(!isProjectDropdownOpen)}>
                      <p>All Projects</p>

                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon ${isProjectDropdownOpen ? 'open' : ''} stroke-color`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsProjectDropdownOpen(false)}>
                <ItemDiv>{renderProjectData}</ItemDiv>
              </Dropdown>

              <Dropdown
                onChangeSearch={onChangeSearchUser}
                content={
                  <DropdownButton>
                    <Button
                      disabled={loading || filterLoading}
                      onClick={() => openMemberDropdown(!isMemberDropdownOpen)}>
                      <p>All Members</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon ${isMemberDropdownOpen ? 'open' : ''} stroke-color`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsMemberDropdownOpen(false)}>
                <ItemDiv>{renderUserData}</ItemDiv>
              </Dropdown>
            </Leftcontent>
            <Rightcontent>
              <Dropdown
                isMinWidth={152}
                content={
                  <DropdownButton ref={buttonRef}>
                    <Button
                      iscustom
                      disabled={loading || filterLoading}
                      onClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
                      <p>{time_filter?.selected_text}</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon ${isStatusDropdownOpen ? 'open' : ''} stroke-color`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsStatusDropdownOpen(false)}>
                <CutstomItemDiv>
                  <ReportDurationDropdown
                    buttonref={buttonRef}
                    setIsStatusDropdownOpen={(value) => setIsStatusDropdownOpen(value)}
                    updateDateRangeChange={(startDate: Date, endDate: Date, selected_text: string) =>
                      updateDateRangeChange(startDate, endDate, selected_text)
                    }
                  />
                </CutstomItemDiv>
              </Dropdown>

              <DropdownButton isMonthPicker>
                <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
                  <Monthpicker>
                    <DateSelection>
                      <SvgIconDiv>
                        <SVGIcon
                          name='calendar-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='fill-color'
                        />
                      </SvgIconDiv>
                      <Text className={loading || filterLoading ? 'disable-calendar' : ''}>{`${moment(
                        time_filter?.selected_range?.startDate
                      ).format('MMM DD')} - ${moment(time_filter?.selected_range?.endDate).format('MMM DD')}`}</Text>
                    </DateSelection>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={
                        isDateDropdownOpen
                          ? 'dropdown-icon arrows'
                          : loading || filterLoading
                          ? 'arrows disabled'
                          : 'arrows'
                      }
                    />
                  </Monthpicker>
                </div>
                {isDateDropdownOpen && (
                  <div className='dropdown-content'>
                    <Datedropbox isLoading={loading}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                          {
                            startDate: new Date(time_filter?.selected_range?.startDate),
                            endDate: new Date(time_filter?.selected_range?.endDate),
                            key: time_filter?.selected_range?.key
                          }
                        ]}
                        maxDate={new Date()}
                      />
                    </Datedropbox>
                  </div>
                )}
              </DropdownButton>
            </Rightcontent>
          </Contentheading>
          {!loading && !dataLoading && (
            <>
              <LineChart projectWiseChartData={projectWiseChartData} onChangeChartType={onChangeChartType} />
              <Tableheader
                timeSheetData={timeSheetData}
                workSummaryData={workSummaryData}
                activityData={activityData}
                onChangeReportFilter={onChangeReportFilter}
              />
            </>
          )}
          {(loading || dataLoading) && <ReportTimelineLoading />}
        </Container>
      </AppLayout>
    </>
  );
};

export default Times;
