import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Modaldiv = styled.div`
  max-width: 692px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Buttondiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 15px 16px 16px;
  border-top: 0.5px solid var(--border-primary);
`;
const Div = styled.div`
  display: flex;
  @media (max-width: 449px) {
    flex-direction: column;
  }
`;
const Leftdiv = styled.div`
  padding: 16px 15px 16px;
  border-right: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 265px;
  width: 100%;
  @media (max-width: 449px) {
    border-bottom: 0.5px solid var(--border-primary);
    border-right: none;
    max-width: -webkit-fill-available;
  }
`;

const Rightdiv = styled.div`
  margin: 7px 0;
  max-width: 376px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    gap: 16px;
  }
`;
const Icon = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  @media (max-width: 449px) {
    width: 40px;
    height: 40px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
const Heading = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const Detail = styled.h2`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    margin-top: -6px;
  }
`;
const Title = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const Features = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  @media (max-width: 449px) {
    grid-column-gap: 12px;
  }
`;
const Featurediv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Subdiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
`;
const Icons = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  .stroke-color {
    padding-top: 3px;
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-primary);
    }
  }
  .fill-color {
    padding-top: 3px;
    width: 16px;
    height: 16px;
    path {
      fill: var(--text-primary);
    }
  }
  .circle-stroke-color {
    padding-top: 3px;
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-primary);
    }
    circle {
      stroke: var(--text-primary);
    }
  }
`;
const Progressdiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Progresstext = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
  span {
    color: var(--brand-primary);
  }
`;
const Text = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
export {
  Modaldiv,
  Buttondiv,
  Div,
  Leftdiv,
  Rightdiv,
  Icon,
  Heading,
  Detail,
  Title,
  Featurediv,
  Subdiv,
  Icons,
  Progressdiv,
  Progresstext,
  Features,
  Text
};
