import React, { useState, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import {
  ColorPickerWrapper,
  ColorArea,
  ColorThumb,
  SpectrumSlider,
  SpectrumThumb,
  ColorInputWrapper,
  HexInput,
  InputLabel
} from './styles';

interface ColorPickerProps {
  value?: string;
  onChange?: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ value = '#000000', onChange }) => {
  const [colorState, setColorState] = useState(() => {
    const rgb = hexToRGB(value);
    const hsv = RGBtoHSV(rgb.r, rgb.g, rgb.b);
    return {
      hue: hsv.h,
      saturation: hsv.s,
      brightness: hsv.v,
      hexValue: value
    };
  });

  const colorAreaRef = useRef<HTMLDivElement>(null);
  const spectrumRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const rafRef = useRef<number>();

  // Memoize color conversion functions
  const updateColor = useCallback(
    (h: number, s: number, v: number) => {
      const rgb = HSVtoRGB(h, s, v);
      const hex = RGBtoHex(rgb.r, rgb.g, rgb.b);
      setColorState((prev) => ({ ...prev, hexValue: hex }));
      onChange?.(hex);
    },
    [onChange]
  );

  const handleColorChange = useCallback(
    (h: number, s: number, v: number) => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }

      rafRef.current = requestAnimationFrame(() => {
        setColorState((prev) => ({
          ...prev,
          hue: h,
          saturation: s,
          brightness: v
        }));
        updateColor(h, s, v);
      });
    },
    [updateColor]
  );

  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  const handleColorAreaMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      isDraggingRef.current = true;

      const handleMove = (e: MouseEvent) => {
        if (!colorAreaRef.current) return;
        const rect = colorAreaRef.current.getBoundingClientRect();
        const x = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
        const y = Math.max(0, Math.min(1, (e.clientY - rect.top) / rect.height));

        const newSaturation = x * 100;
        const newBrightness = 100 - y * 100;

        handleColorChange(colorState.hue, newSaturation, newBrightness);
      };

      const handleMouseUp = () => {
        isDraggingRef.current = false;
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleMouseUp);
      handleMove(e.nativeEvent);
    },
    [colorState.hue, handleColorChange]
  );

  const handleSpectrumMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      isDraggingRef.current = true;

      const handleMove = (e: MouseEvent) => {
        if (!spectrumRef.current) return;
        const rect = spectrumRef.current.getBoundingClientRect();
        const x = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
        const newHue = x * 360;

        handleColorChange(newHue, colorState.saturation, colorState.brightness);
      };

      const handleMouseUp = () => {
        isDraggingRef.current = false;
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleMouseUp);
      handleMove(e.nativeEvent);
    },
    [colorState.saturation, colorState.brightness, handleColorChange]
  );

  const handleHexChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newHex = e.target.value;
      if (/^#[0-9A-F]{6}$/i.test(newHex)) {
        const rgb = hexToRGB(newHex);
        const hsv = RGBtoHSV(rgb.r, rgb.g, rgb.b);
        setColorState({
          hue: hsv.h,
          saturation: hsv.s,
          brightness: hsv.v,
          hexValue: newHex
        });
        onChange?.(newHex);
      } else {
        setColorState((prev) => ({ ...prev, hexValue: newHex }));
      }
    },
    [onChange]
  );

  // Memoize style objects
  const thumbStyle = useMemo(
    () => ({
      left: `${colorState.saturation}%`,
      top: `${100 - colorState.brightness}%`
    }),
    [colorState.saturation, colorState.brightness]
  );

  const spectrumThumbStyle = useMemo(
    () => ({
      left: `${(colorState.hue / 360) * 100}%`
    }),
    [colorState.hue]
  );

  return (
    <ColorPickerWrapper>
      <ColorArea ref={colorAreaRef} hue={colorState.hue} onMouseDown={handleColorAreaMouseDown}>
        <ColorThumb style={thumbStyle} />
      </ColorArea>

      <SpectrumSlider ref={spectrumRef} onMouseDown={handleSpectrumMouseDown}>
        <SpectrumThumb style={spectrumThumbStyle} />
      </SpectrumSlider>

      <ColorInputWrapper>
        <InputLabel>HEX</InputLabel>
        <HexInput value={colorState.hexValue} onChange={handleHexChange} placeholder='#000000' />
      </ColorInputWrapper>
    </ColorPickerWrapper>
  );
};

// Color conversion utilities
const HSVtoRGB = (h: number, s: number, v: number) => {
  s = s / 100;
  v = v / 100;
  const i = Math.floor(h / 60);
  const f = h / 60 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  let r = 0,
    g = 0,
    b = 0;
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
};

const RGBtoHex = (r: number, g: number, b: number) => {
  return `#${[r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('')}`;
};

const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : { r: 0, g: 0, b: 0 };
};

const RGBtoHSV = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;
  let h = 0;
  const s = max === 0 ? 0 : d / max;
  const v = max;

  if (max === min) {
    h = 0;
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    h: h * 360,
    s: s * 100,
    v: v * 100
  };
};

export default memo(ColorPicker);
