import { makeAutoObservable } from 'mobx';
import { WorkspaceModel, createObservableWorkspace } from '../models/workspace';
import { RawWorkspace } from '../interfaces/workspace';

export class WorkspaceStore {
  currentWorkspace: WorkspaceModel | null = null;
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentWorkspace(rawWorkspace: RawWorkspace | null) {
    this.currentWorkspace = rawWorkspace ? createObservableWorkspace(rawWorkspace) : null;
    this.loading = false;
  }
}
