/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import { Bottomdata, CutstomItemDiv, Table } from '../styles/style';
import {
  Header,
  Contentheading,
  Rightcontent,
  Leftcontent,
  Text,
  Container,
  Button,
  Monthpicker,
  Datedropbox,
  Empty,
  DropdownButton,
  DateSelection,
  HeaderLeft,
  BackIcon,
  CsvDiv,
  TableSection,
  ItemDiv
} from '../styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useHistory } from 'react-router-dom';
import { getProjectList } from '../../../services/projectServices';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { getCustomerList } from '../../../services/customerServices';
import { CustomerDetailsInterface } from '../../../interfaces/CustomerInterface';
import { currencyWithDecimal, isEmpty } from '../../../helpers/common';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { SelectionRangeInterface } from '../../../interfaces/TimeReportInterface';
import { REPORT_PAYMENT_LOADINGSTATE_DATA, TIME_DATE_SELECTED_TEXT } from '../../../global/constants';
import { getPaymentReportData } from '../../../services/reportService';
import { PaymentReportDetails } from '../../../interfaces/ReportInterface';
import { setFilteredPaymentReportData } from '../../../actions/reportActions';
import EmptyState from '../../../component/emptyState';
import { CSVLink } from 'react-csv';
import { PaymentReportCSVHeader } from '../../../global/row';
import reportdarkempty from '../../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../../assets/images/emptystates/report-empty.svg';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import ReportDurationDropdown from '../../../component/dropdowns/reportdurationdropdown/reportdurationdropdown';
import ReportLoadingState from '../../../component/loading/reportLoadingState';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

const Payments: React.FC = () => {
  // Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);
  // States
  const [dateSelectedText, setDateSelectedText] = useState('Custom');
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState<SelectionRangeInterface>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [exportData, setExportData]: any = useState([]);
  const [projectList, setProjectList] = useState<ProjectDetailInterface[]>([]);
  const [customerList, setCustomerList] = useState<CustomerDetailsInterface[]>([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState<CustomerDetailsInterface[]>([]);
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [selectAllCustomer, setSelectAllCustomer] = useState(true);
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    report: reportSelector,
    workspace: workspaceSelector,
    settings: settingsSelector,
    auth: authSelector
  } = stateSelector;
  const { paymentReportData, filteredPaymentReportData, loading: reducerLoading } = reportSelector;
  const { workspace } = workspaceSelector;
  const { themeMode } = settingsSelector;
  const { userDetails } = authSelector;
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  let loading = stateLoading || reducerLoading;

  const applyFilter = useCallback(
    (data: PaymentReportDetails[], projects?: ProjectDetailInterface[], customers?: CustomerDetailsInterface[]) => {
      let filteredData = JSON.parse(JSON.stringify(data || []));
      const filteredCustomers: string[] = customers?.filter((item) => item?.checked)?.map((x) => x?.id) || [];
      filteredData = filteredData.filter((item: PaymentReportDetails) => filteredCustomers.includes(item?.customerId));

      dispatch(setFilteredPaymentReportData(filteredData));
      if (filteredData?.length > 0) {
        const data = filteredData?.map((item: any) => {
          return {
            invoiceNumber: item?.invoiceNumber,
            projects: !isEmpty(item?.project) ? item?.project?.map((project: any) => project?.name).join(', ') : '',
            customerCompanyName: item?.customerCompanyName,
            paidOn: moment(item?.paidOn).format(userDetails?.dateFormat),
            paidAmount: currencyWithDecimal(item?.paidAmount)
          };
        });
        setExportData(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const loadData = useCallback(async () => {
    try {
      setStateLoading(true);
      if (!isEmpty(workspace?.id)) {
        if (!workspace?.isAdmin) history.push('/');
        const result = await Promise.all([
          dispatch(getPaymentReportData(workspace?.id, selectionRange?.startDate, selectionRange?.endDate)),
          dispatch(getProjectList()),
          dispatch(getCustomerList())
        ]);
        let projectsResponse = result[1];
        let customerResponse = result[2];
        projectsResponse = projectsResponse?.map((item: ProjectDetailInterface) => {
          return { ...item, checked: true };
        });
        customerResponse = customerResponse?.map((item: CustomerDetailsInterface) => {
          return { ...item, checked: true };
        });
        applyFilter(result[0], projectsResponse, customerResponse);
        setProjectList(projectsResponse);

        setCustomerList(customerResponse);
        setFilteredCustomerList(customerResponse);
      }
    } catch (e) {
      captureException(e);
    } finally {
      setStateLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRange.endDate, workspace.id]);

  useEffect(() => {
    loadData();
  }, [loadData, history]);

  useEffect(() => {
    return () => {
      dispatch(setFilteredPaymentReportData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMemberDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsCustomerDropdownOpen(value);
  }, []);

  const onSelectAllCustomerClick = useCallback(
    (customerList: CustomerDetailsInterface[], value: boolean, projects: ProjectDetailInterface[]) => {
      setSelectAllCustomer(value);
      let customerListClone: CustomerDetailsInterface[] = JSON.parse(JSON.stringify(customerList || []));
      customerListClone = customerListClone?.map((item) => {
        return { ...item, checked: value };
      });
      setCustomerList(customerListClone);
      setFilteredCustomerList(customerListClone);
      applyFilter(paymentReportData, projects, customerListClone);
    },
    [applyFilter, paymentReportData]
  );

  const onChangeSearchCustomer = useCallback(
    (value: string) => {
      setSearchCustomer(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            customerList?.filter((item: CustomerDetailsInterface) =>
              item?.companyName?.toLowerCase().includes(value?.toLowerCase())
            ) || [];
          setFilteredCustomerList(newList);
        }, 300);
      } else {
        setFilteredCustomerList(customerList);
      }
    },
    [customerList]
  );

  const onCheckCustomer = useCallback(
    (
      allUserList: CustomerDetailsInterface[],
      filteredList: CustomerDetailsInterface[],
      userId: string,
      value: boolean,
      projects: ProjectDetailInterface[]
    ) => {
      const updatedAllList = allUserList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const uncheckedTasks = updatedFilteredList?.find((x) => !x?.checked);
      if (uncheckedTasks || updatedFilteredList?.length === 0) setSelectAllCustomer(false);
      else setSelectAllCustomer(true);
      setCustomerList(updatedAllList);
      setFilteredCustomerList(updatedFilteredList);
      applyFilter(paymentReportData, projects, updatedAllList);
    },
    [applyFilter, paymentReportData]
  );

  const renderCustomerData = useMemo(() => {
    return (
      <Bottomdata>
        {isEmpty(searchCustomer) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={selectAllCustomer}
            onClick={() => onSelectAllCustomerClick(filteredCustomerList, !selectAllCustomer, projectList)}
          />
        )}
        {(isEmpty(searchCustomer) ? customerList : filteredCustomerList)?.map((item) => {
          return (
            <DropdownItem
              key={item?.id}
              label={item?.companyName}
              isCheckBox={true}
              isChecked={item?.checked}
              onClick={(e) =>
                onCheckCustomer(customerList, filteredCustomerList, item?.id, !item?.checked, projectList)
              }
            />
          );
        })}
      </Bottomdata>
    );
  }, [
    searchCustomer,
    selectAllCustomer,
    customerList,
    filteredCustomerList,
    onSelectAllCustomerClick,
    projectList,
    onCheckCustomer
  ]);

  const openStatusDropdown = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsDateDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  }, []);

  const openDateDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(value);
    setIsCustomerDropdownOpen(false);
  }, []);

  const handleSelect = useCallback((ranges: { selection: { startDate: Date; endDate: Date; key: string } }) => {
    setSelectionRange(ranges.selection);
    setDateSelectedText(TIME_DATE_SELECTED_TEXT.CUSTOM);
  }, []);

  return (
    <>
      <AppLayout>
        <Header>
          <HeaderLeft>
            <BackIcon onClick={() => history.goBack()}>
              <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
            </BackIcon>
            Payment
          </HeaderLeft>
          {filteredPaymentReportData?.length > 0 && (
            <CSVLink data={exportData || []} headers={PaymentReportCSVHeader} filename={`payment_report.csv`}>
              <CsvDiv>Export as CSV</CsvDiv>
            </CSVLink>
          )}
        </Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <Dropdown
                onChangeSearch={onChangeSearchCustomer}
                content={
                  <DropdownButton>
                    <div onClick={() => openMemberDropdown(!isCustomerDropdownOpen)}>
                      <Button>
                        <p>Client</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isCustomerDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsCustomerDropdownOpen(false)}>
                <ItemDiv> {renderCustomerData}</ItemDiv>
              </Dropdown>
            </Leftcontent>
            <Rightcontent>
              <Dropdown
                isMinWidth={152}
                content={
                  <DropdownButton>
                    <div ref={buttonRef} onClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
                      <Button iscustom>
                        <p>{dateSelectedText}</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isStatusDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsStatusDropdownOpen(false)}>
                <CutstomItemDiv>
                  <ReportDurationDropdown
                    buttonref={buttonRef}
                    setSelectionRange={(value) => setSelectionRange(value)}
                    setIsStatusDropdownOpen={(value) => setIsStatusDropdownOpen(value)}
                    setDateSelectedText={(value) => setDateSelectedText(value)}
                  />
                </CutstomItemDiv>
              </Dropdown>

              <DropdownButton isMonthPicker>
                <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
                  <Monthpicker>
                    <DateSelection>
                      <SVGIcon name='calendar-icon' width='18' height='18' viewBox='0 0 18 18' className='fill-color' />
                      <Text>{`${moment(selectionRange?.startDate).format('MMM DD')} - ${moment(
                        selectionRange?.endDate
                      ).format('MMM DD')}`}</Text>
                    </DateSelection>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isDateDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Monthpicker>
                </div>
                {isDateDropdownOpen && (
                  <div className='dropdown-content'>
                    <Datedropbox>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        maxDate={new Date()}
                      />
                    </Datedropbox>
                  </div>
                )}
              </DropdownButton>
            </Rightcontent>
          </Contentheading>
          {(filteredPaymentReportData?.length === 0 || !filteredPaymentReportData) && !loading && (
            <Empty>
              <EmptyState
                header={'There is no report data.'}
                title={'You have no report data for this date. Search for another date.'}
                image={getImageSource()}
                name={''}
                hideButton={true}
              />
            </Empty>
          )}
          {loading && <ReportLoadingState header={REPORT_PAYMENT_LOADINGSTATE_DATA} />}
          {!loading && filteredPaymentReportData?.length > 0 && (
            <TableSection>
              <Table ispayment>
                <thead>
                  <tr>
                    <th>invoice no</th>
                    <th style={{ width: '500' }}>Projects</th>
                    <th>Client Company</th>
                    <th>paid on</th>
                    <th>paid amount</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPaymentReportData?.map((item: PaymentReportDetails) => (
                    <tr key={item['_id']}>
                      <td>{item?.invoiceNumber}</td>
                      <td>{item?.project?.map((project) => project.name).join(', ')}</td>
                      <td>{item?.customerCompanyName}</td>
                      <td>{moment(item?.paidOn).format(userDetails?.dateFormat)}</td>
                      <td>
                        {currencyWithDecimal(item?.paidAmount)} {item?.invoiceCurrency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableSection>
          )}
        </Container>
      </AppLayout>
    </>
  );
};

export default Payments;
