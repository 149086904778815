import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import { Bottomdata, CutstomItemDiv, Table } from '../styles/style';
import {
  Header,
  Contentheading,
  Rightcontent,
  Monthpicker,
  Text,
  Container,
  Button,
  Datedropbox,
  Empty,
  DropdownButton,
  Leftcontent,
  BackIcon,
  CsvDiv,
  HeaderLeft,
  TableSection,
  DateSelection,
  SvgIconDiv,
  ItemDiv
} from '../styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceList } from '../../../actions/invoiceActions';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { CustomerDetailsInterface } from '../../../interfaces/CustomerInterface';
import { isEmpty } from 'lodash';
import { getProjectList } from '../../../services/projectServices';
import { getCustomerList } from '../../../services/customerServices';
import { InvoiceReportStatusList, currencySymbols, invoiceReportCSVHeader } from '../../../global/row';
import moment from 'moment';
import { currencyWithDecimal } from '../../../helpers/common';
import { setFilteredInvoiceReportData, setReportLoader } from '../../../actions/reportActions';
import {
  ExportInvoiceDataInterface,
  SelectionRangeInterface,
  StatusListInterface
} from '../../../interfaces/TimeReportInterface';
import { REPORT_INVOICE_LOADINGSTATE_DATA, TIME_DATE_SELECTED_TEXT } from '../../../global/constants';
import { DateRange } from 'react-date-range';
import EmptyState from '../../../component/emptyState';
import reportempty from '../../../assets/images/emptystates/report-empty.svg';
import { getInvoiceReportData } from '../../../services/reportService';
import reportdarkempty from '../../../assets/images/emptystates/reportdarkempty.svg';
import { CSVLink } from 'react-csv';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { InvoiceReportInterface } from '../../../interfaces/ReportInterface';
import ReportDurationDropdown from '../../../component/dropdowns/reportdurationdropdown/reportdurationdropdown';
import { useHistory } from 'react-router-dom';
import ReportLoadingState from '../../../component/loading/reportLoadingState';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

const ReportInvoice: React.FC = () => {
  //Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    report: reportSelector,
    settings: settingsSelector,
    workspace: workspaceSelector,
    auth: authSelector
  } = stateSelector;
  const { invoiceReportData, filteredInvoiceReportData, loading } = reportSelector;
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { userDetails } = authSelector;
  // States
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isCustomDropdownOpen, setIsCustomDropdownOpen] = useState(false);
  const [selectAllCustomer, setSelectAllCustomer] = useState(true);
  const [customerList, setCustomerList] = useState<CustomerDetailsInterface[]>([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState<CustomerDetailsInterface[]>([]);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [projectList, setProjectList] = useState<ProjectDetailInterface[]>([]);
  const [filteredProjectList, setFilteredProjectList] = useState<ProjectDetailInterface[]>([]);
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [searchProject, setSearchProject] = useState('');
  const [selectAllStatus, setSelectAllStatus] = useState(true);
  const [statusList, setStatusList] = useState<StatusListInterface[]>([]);
  const [dateSelectedText, setDateSelectedText] = useState('Custom');
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState<SelectionRangeInterface>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  // Other variables
  const dispatch = useDispatch();
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  const [exportData, setExportData] = useState<ExportInvoiceDataInterface[]>([]);
  const history = useHistory();

  const applyFilter = useCallback(
    (
      data: InvoiceReportInterface[],
      projects?: ProjectDetailInterface[],
      customers?: CustomerDetailsInterface[],
      status?: StatusListInterface[],
      range?: SelectionRangeInterface
    ) => {
      if (isEmpty(range)) {
        range = selectionRange;
      }
      let filteredData: InvoiceReportInterface[] = JSON.parse(JSON.stringify(data));
      const filteredProjects: string[] = projects?.filter((item) => item?.checked)?.map((x) => x?.id) || [];
      const filteredCustomers: string[] = customers?.filter((item) => item?.checked)?.map((x) => x?.id) || [];
      const filteredStatus: number[] = status?.filter((item) => item?.checked)?.map((x) => x?.key) || [];
      filteredData = filteredData.filter((item) => filteredCustomers.includes(item?.customerId));
      filteredData = filteredData.filter((item) => {
        return (
          item.project.length === 0 || item.project.some((project) => filteredProjects.includes(project['_id'] || ''))
        );
      });
      filteredData = filteredData.filter((item) => filteredStatus.includes(item?.status));
      filteredData = filteredData?.filter(
        (item) =>
          moment(item?.paidOn).format('YYYY-MM-DD') >= moment(range?.startDate).format('YYYY-MM-DD') &&
          moment(item?.paidOn).format('YYYY-MM-DD') <= moment(range?.endDate).format('YYYY-MM-DD')
      );
      dispatch(setFilteredInvoiceReportData(filteredData));
      if (filteredData?.length > 0) {
        const data = filteredData?.map((item) => {
          return {
            status: item?.statusText,
            paidOn: moment(item?.paidOn).format(userDetails?.dateFormat),
            dueOn: moment(item?.dueDate).format(userDetails?.dateFormat),
            invoiceNo: item?.invoiceNumber,
            projects: !isEmpty(item?.project) ? item?.project?.map((project) => project?.name).join(', ') : '',
            rate: currencyWithDecimal(item?.totalAmount),
            discount: currencyWithDecimal(item?.invoiceDiscount)
          };
        });
        setExportData(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectionRange]
  );

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        dispatch(setReportLoader(true));
        const result = await Promise.all([
          dispatch(getInvoiceReportData(workspace?.id)),
          dispatch(getProjectList()),
          dispatch(getCustomerList())
        ]);
        dispatch(setInvoiceList(result[0]));
        let projectsResponse = result[1];
        let customerResponse = result[2];
        projectsResponse = projectsResponse?.map((item: ProjectDetailInterface) => {
          return { ...item, checked: true };
        });
        customerResponse = customerResponse?.map((item: CustomerDetailsInterface) => {
          return { ...item, checked: true };
        });
        const statusResponse = InvoiceReportStatusList?.map((item: StatusListInterface) => {
          return { ...item, checked: true };
        });
        setStatusList(statusResponse);
        applyFilter(result[0], projectsResponse, customerResponse, statusResponse);
        setProjectList(projectsResponse);
        setFilteredProjectList(projectsResponse);
        setCustomerList(customerResponse);
        setFilteredCustomerList(customerResponse);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      dispatch(setReportLoader(false));
    }
  }, [applyFilter, dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickonStatus = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsCustomerDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const onClickonClient = useCallback((value: boolean) => {
    setIsCustomerDropdownOpen(value);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const onClickonProject = useCallback((value: boolean) => {
    setIsProjectDropdownOpen(value);
    setIsCustomerDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsCustomDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const openCustomDropdown = useCallback((value: boolean) => {
    setIsCustomDropdownOpen(value);
    setIsDateDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
    setIsStatusDropdownOpen(false);
  }, []);

  const openDateDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(value);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
    setIsCustomDropdownOpen(false);
  }, []);

  const onSelectAllCustomerClick = useCallback(
    (
      customerList: CustomerDetailsInterface[],
      value: boolean,
      projects: ProjectDetailInterface[],
      statusList: StatusListInterface[]
    ) => {
      setSelectAllCustomer(value);
      let customerListClone: CustomerDetailsInterface[] = JSON.parse(JSON.stringify(customerList || []));
      customerListClone = customerListClone?.map((item) => {
        return { ...item, checked: value };
      });
      setCustomerList(customerListClone);
      setFilteredCustomerList(customerListClone);
      applyFilter(invoiceReportData, projects, customerListClone, statusList);
    },
    [applyFilter, invoiceReportData]
  );

  const onChangeSearchCustomer = useCallback(
    (value: string) => {
      setSearchCustomer(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            customerList?.filter((item: CustomerDetailsInterface) =>
              item?.companyName?.toLowerCase().includes(value?.toLowerCase())
            ) || [];
          setFilteredCustomerList(newList);
        }, 300);
      } else {
        setFilteredCustomerList(customerList);
      }
    },
    [customerList]
  );

  const onCheckCustomer = useCallback(
    (
      allUserList: CustomerDetailsInterface[],
      filteredList: CustomerDetailsInterface[],
      userId: string,
      value: boolean,
      projects: ProjectDetailInterface[],
      statusList: StatusListInterface[]
    ) => {
      const updatedAllList = allUserList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === userId ? { ...item, checked: value } : item;
      }, []);
      const uncheckedTasks = updatedFilteredList?.find((x) => !x?.checked);
      if (uncheckedTasks || updatedFilteredList?.length === 0) setSelectAllCustomer(false);
      else setSelectAllCustomer(true);
      setCustomerList(updatedAllList);
      setFilteredCustomerList(updatedFilteredList);
      applyFilter(invoiceReportData, projects, updatedAllList, statusList);
    },
    [applyFilter, invoiceReportData]
  );

  const renderCustomerData = useMemo(() => {
    return (
      <Bottomdata>
        {isEmpty(searchCustomer) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={selectAllCustomer}
            onClick={() => onSelectAllCustomerClick(filteredCustomerList, !selectAllCustomer, projectList, statusList)}
          />
        )}
        {(isEmpty(searchCustomer) ? customerList : filteredCustomerList)?.map((item) => {
          return (
            <DropdownItem
              key={item?.id}
              label={item?.companyName}
              isCheckBox={true}
              isChecked={item?.checked}
              onClick={(e) =>
                onCheckCustomer(customerList, filteredCustomerList, item?.id, !item?.checked, projectList, statusList)
              }
            />
          );
        })}
      </Bottomdata>
    );
  }, [
    searchCustomer,
    selectAllCustomer,
    customerList,
    filteredCustomerList,
    onSelectAllCustomerClick,
    projectList,
    statusList,
    onCheckCustomer
  ]);

  const onSelectAllProjectClick = useCallback(
    (
      projectList: ProjectDetailInterface[],
      value: boolean,
      customers: CustomerDetailsInterface[],
      statusList: StatusListInterface[]
    ) => {
      setSelectAllProject(value);
      let projectListClone: ProjectDetailInterface[] = JSON.parse(JSON.stringify(projectList));
      projectListClone = projectListClone?.map((item) => {
        return { ...item, checked: value };
      });
      setProjectList(projectListClone);
      setFilteredProjectList(projectListClone);
      applyFilter(invoiceReportData, projectListClone, customers, statusList);
    },
    [applyFilter, invoiceReportData]
  );

  const onCheckProject = useCallback(
    (
      allProjectList: ProjectDetailInterface[],
      filteredList: ProjectDetailInterface[],
      projectId: string,
      value: boolean,
      customers: CustomerDetailsInterface[],
      statusList: StatusListInterface[]
    ) => {
      const updatedAllList = allProjectList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const uncheckedTasks = updatedFilteredList?.find((x) => !x?.checked);
      if (uncheckedTasks || updatedFilteredList?.length === 0) setSelectAllProject(false);
      else setSelectAllProject(true);
      setProjectList(updatedAllList);
      setFilteredProjectList(updatedFilteredList);
      applyFilter(invoiceReportData, updatedAllList, customers, statusList);
    },
    [applyFilter, invoiceReportData]
  );

  const onChangeSearchProject = useCallback(
    (value: string) => {
      setSearchProject(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            projectList?.filter((item: { name: string }) => item?.name?.toLowerCase().includes(value?.toLowerCase())) ||
            [];
          setFilteredProjectList(newList);
        }, 500);
      } else {
        setFilteredProjectList(projectList);
      }
    },
    [projectList]
  );

  const renderProjectData = useMemo(() => {
    return (
      // eslint-disable-next-line react/jsx-filename-extension

      <Bottomdata isborderredius>
        {isEmpty(searchProject) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            isChecked={selectAllProject}
            onClick={() => onSelectAllProjectClick(projectList, !selectAllProject, customerList, statusList)}
          />
        )}
        {(isEmpty(searchProject) ? projectList : filteredProjectList)?.map((item) => {
          return (
            <DropdownItem
              key={item?.id}
              label={item?.name}
              isCheckBox={true}
              isChecked={item?.checked}
              onClick={(e) =>
                onCheckProject(projectList, filteredProjectList, item?.id, !item?.checked, customerList, statusList)
              }
            />
          );
        })}
      </Bottomdata>
    );
  }, [
    customerList,
    filteredProjectList,
    onCheckProject,
    onSelectAllProjectClick,
    projectList,
    searchProject,
    selectAllProject,
    statusList
  ]);

  const onSelectAllStatus = useCallback(
    async (
      statusList: StatusListInterface[],
      value: boolean,
      projects: ProjectDetailInterface[],
      customerList: CustomerDetailsInterface[]
    ) => {
      setSelectAllStatus(value);
      const updatedStatusList = statusList?.map((ele) => {
        return { ...ele, checked: value };
      });
      setStatusList(updatedStatusList);
      applyFilter(invoiceReportData, projects, customerList, updatedStatusList);
    },
    [applyFilter, invoiceReportData]
  );

  const onChangeStatus = useCallback(
    async (item: number, value: boolean) => {
      const updatedStatusList = statusList?.map((ele) => {
        return ele?.key === item ? { ...ele, checked: value } : ele;
      });
      const isAllStatusSelected = updatedStatusList.every((user: StatusListInterface) => user.checked);
      setSelectAllStatus(isAllStatusSelected);

      setStatusList(updatedStatusList);
      applyFilter(invoiceReportData, projectList, customerList, updatedStatusList, selectionRange);
    },
    [applyFilter, customerList, invoiceReportData, projectList, selectionRange, statusList]
  );

  const handleSelect = useCallback(
    (ranges: { selection: { startDate: Date; endDate: Date; key: string } }) => {
      setSelectionRange(ranges.selection);
      setDateSelectedText(TIME_DATE_SELECTED_TEXT.CUSTOM);
      applyFilter(invoiceReportData, projectList, customerList, statusList, ranges.selection);
    },
    [applyFilter, customerList, invoiceReportData, projectList, statusList]
  );

  return (
    <>
      <AppLayout>
        <Header>
          <HeaderLeft>
            <BackIcon onClick={() => history.goBack()}>
              <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
            </BackIcon>
            Invoices
          </HeaderLeft>
          {filteredInvoiceReportData?.length > 0 && (
            <CSVLink data={exportData || []} headers={invoiceReportCSVHeader} filename={`invoice_report.csv`}>
              <CsvDiv>Export as CSV</CsvDiv>
            </CSVLink>
          )}
        </Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <Dropdown
                content={
                  <DropdownButton>
                    <div>
                      <Button onClick={() => onClickonStatus(!isStatusDropdownOpen)}>
                        <p>Status</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isStatusDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsStatusDropdownOpen(false)}>
                <ItemDiv>
                  <Bottomdata isborderredius>
                    <DropdownItem
                      label='Select All'
                      isCheckBox={true}
                      isChecked={selectAllStatus}
                      onClick={() => onSelectAllStatus(statusList, !selectAllStatus, projectList, customerList)}
                    />

                    {statusList?.map((item) => {
                      return (
                        <DropdownItem
                          key={item?.key}
                          label={item?.label}
                          isCheckBox={true}
                          isChecked={item?.checked}
                          onClick={() => onChangeStatus(item?.key, !item?.checked)}
                        />
                      );
                    })}
                  </Bottomdata>
                </ItemDiv>
              </Dropdown>
              <Dropdown
                onChangeSearch={onChangeSearchCustomer}
                content={
                  <DropdownButton>
                    <div>
                      <Button onClick={() => onClickonClient(!isCustomerDropdownOpen)}>
                        <p>Client</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isCustomDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsCustomDropdownOpen(false)}>
                <ItemDiv>{renderCustomerData}</ItemDiv>
              </Dropdown>
              <Dropdown
                onChangeSearch={onChangeSearchProject}
                content={
                  <DropdownButton isProject={true}>
                    <Button onClick={() => onClickonProject(!isProjectDropdownOpen)}>
                      <p>Project</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon ${isProjectDropdownOpen ? 'open' : ''} stroke-color`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsProjectDropdownOpen(false)}>
                <ItemDiv> {renderProjectData}</ItemDiv>
              </Dropdown>
            </Leftcontent>
            <Rightcontent>
              <Dropdown
                isMinWidth={152}
                content={
                  <DropdownButton ref={buttonRef}>
                    <div onClick={() => openCustomDropdown(!isCustomDropdownOpen)}>
                      <Button iscustom>
                        <p>{dateSelectedText}</p>
                        <SVGIcon
                          name='dropdown-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          className={`dropdown-icon ${isCustomDropdownOpen ? 'open' : ''} stroke-color`}
                        />
                      </Button>
                    </div>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsCustomDropdownOpen(false)}>
                <CutstomItemDiv>
                  <ReportDurationDropdown
                    buttonref={buttonRef}
                    setSelectionRange={(value) => setSelectionRange(value)}
                    setIsStatusDropdownOpen={(value) => setIsStatusDropdownOpen(value)}
                    setDateSelectedText={(value) => setDateSelectedText(value)}
                  />
                </CutstomItemDiv>
              </Dropdown>

              <DropdownButton isMonthPicker>
                <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
                  <Monthpicker>
                    <DateSelection>
                      <SvgIconDiv>
                        <SVGIcon
                          name='calendar-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='fill-color'
                        />
                      </SvgIconDiv>
                      <Text>{`${moment(selectionRange?.startDate).format('MMM DD')} - ${moment(
                        selectionRange?.endDate
                      ).format('MMM DD')}`}</Text>
                    </DateSelection>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isDateDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Monthpicker>
                </div>
                {isDateDropdownOpen && (
                  <div className='dropdown-content'>
                    <Datedropbox>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        maxDate={new Date()}
                      />
                    </Datedropbox>
                  </div>
                )}
              </DropdownButton>
            </Rightcontent>
          </Contentheading>
          {(filteredInvoiceReportData?.length === 0 || !filteredInvoiceReportData) && !loading && (
            <Empty>
              <EmptyState
                header={'There is no report data.'}
                title={'You have no any report data for this date.Search for another date.'}
                image={getImageSource()}
                name={''}
                hideButton={true}
              />
            </Empty>
          )}
          {loading && <ReportLoadingState header={REPORT_INVOICE_LOADINGSTATE_DATA} rowCount={6} />}
          {!loading && filteredInvoiceReportData?.length > 0 && (
            <TableSection>
              <Table isreportinvoice>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>paid on </th>
                    <th>due on</th>
                    <th>invoice no</th>
                    <th>projects name</th>
                    <th>rate</th>
                    <th>discount</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInvoiceReportData?.map((item) => {
                    const currency = currencySymbols.find((x) => x.code === item?.invoiceCurrency);
                    return (
                      <tr key={item['_id']}>
                        <td
                          className={
                            item?.status === 5
                              ? 'paid'
                              : item?.status === 4
                              ? 'partiallyPaid'
                              : item?.status === 3
                              ? 'pending'
                              : item?.status === 2
                              ? 'overDue'
                              : item?.status === 1
                              ? 'draft'
                              : ''
                          }>
                          {item?.statusText}
                        </td>
                        <td>{item?.paidOn ? moment(item?.paidOn).format(userDetails?.dateFormat) : 'No Paid Date'}</td>
                        <td>{item?.dueDate ? moment(item?.dueDate).format(userDetails?.dateFormat) : 'No Due Date'}</td>
                        <td>{item?.invoiceNumber}</td>
                        <td>{item?.project?.map((project) => project.name).join(', ')}</td>
                        <td>
                          {currency?.symbol}
                          {currencyWithDecimal(item?.totalAmount)}
                        </td>
                        <td>
                          {currency?.symbol}
                          {currencyWithDecimal(item?.totalDiscount)}
                        </td>
                      </tr>
                    );
                  }, [])}
                </tbody>
              </Table>
            </TableSection>
          )}
        </Container>
      </AppLayout>
    </>
  );
};

export default ReportInvoice;
