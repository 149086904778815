import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clearDocData, setDocData } from '../../../../../actions/projectActions';
import AppLayout from '../../../../../component/appLayout';
import {
  DocumentBlock,
  DocumentHeader,
  DocumentForm,
  DocumentButton,
  Leftdiv,
  IconPoint,
  Rightdiv,
  Icon,
  AvatarDiv,
  Buttondiv,
  DropDiv
} from './styles';
import { getUniqueId, getUrlParam, isEmpty } from '../../../../../helpers/common';
import {
  createNewDoc,
  getDocumentData,
  getFileDetailsById,
  updateDocumentData,
  updateFileData
} from '../../../../../services/projectServices';
import { captureException } from '../../../../../services/logService';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import Button from '../../../../../component/button';
import { setSuccessMessage } from '../../../../../actions/messageActions';
import { COLOR_THEME_TYPE, FILE_TYPES, LINK_FILE_DATA } from '../../../../../global/constants';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import Avatar from '../../../../../component/avatar/Avatar';
import ModalCustom from '../../../../../component/models/modal';
import Importmodal from '../../../../../component/models/importModal';
import Deletemodal from '../../../../../component/models/deleteModel';
import SharedocModal from '../../../../../component/models/shareDocmodal';
import { ProjectFileData, ProjectFilesInterface } from '../../../../../interfaces/ProjectInterface';
import VersionHistoryModal from '../../../../../component/models/versionHistoryModal';
import { REACT_APP_BLOCKSUITE_URL } from '../../../../../global/environment';
import { getFirebaseToken } from '../../../../../services/authServices';
import FilesModal from '../../../../../component/models/filesmodal';
import { Dropdown, DropdownItem } from '../../../../../component/Dropdown';
import ExportSubMenu from './exportSubMenu';
import { useMobileDevice } from '../../../../../global/useMobile';
import { Divider } from '../../../../../component/Dropdown/styles';

const CreateNewDoc: React.FC = () => {
  // Refs
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // States
  const [loading, setLoading] = useState(false);
  const [firebaseCustomToken, setFirebaseCustomToken] = useState('');
  const [activeUsers, setActiveUsers] = useState<{ name: string; profile?: string }[]>([]);
  const [currentFileItem, setCurrentFileItem] = useState<ProjectFileData | null>(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [listenerAdded, setListenerAdded] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isVersionHistoryModelOpen, setIsVersionHistoryModelOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { docData } = projectSelector;
  const { workspace } = workspaceSelector;
  const isDarkMode = settingsSelector.themeMode.theme === COLOR_THEME_TYPE.DARK;

  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const params: { docid: string; id: string } = useParams();
  const oldDocUrl = 'https://blocksuit-client.vercel.app';
  const folderId = getUrlParam(history.location.search, 'folder');
  const docUrl = REACT_APP_BLOCKSUITE_URL || oldDocUrl;

  // for handle import click
  const handleImportClick = useCallback(() => {
    setIsImportModalOpen(true);
  }, []);

  // for handle close import model
  const handleImportModalClose = useCallback(() => {
    setIsImportModalOpen(false);
  }, []);

  // for open delete model
  const openDeleteModel = useCallback(() => {
    setDeleteModelOpen(true);
  }, []);

  // for close delete model
  const closeDeleteModel = useCallback(() => {
    setDeleteModelOpen(false);
  }, []);

  // for open version history model
  const openVersionHistoryModel = useCallback(() => {
    setIsVersionHistoryModelOpen(true);
  }, []);

  // for close version history model36
  const closeVersionHistoryModel = useCallback(() => {
    setIsVersionHistoryModelOpen(false);
  }, []);

  // load initial data
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (workspace?.id) {
        const firebaseToken = await dispatch(getFirebaseToken(workspace?.id));
        setFirebaseCustomToken(firebaseToken);
        if (!isEmpty(params?.docid)) {
          await dispatch(getDocumentData(params?.docid));
        }
      }
    } catch (e) {
      captureException(e);
      console.log('error : ', e);
    } finally {
      setLoading(false);
    }
  }, [workspace?.id, dispatch, params?.docid]);

  // for handle save/update button
  const handleSave = (e: SyntheticEvent) => {
    e.preventDefault();
    iframeRef.current?.contentWindow?.postMessage({ type: 'save' }, oldDocUrl);
  };

  // Handles live user updates and save events via a message listener for the workspace.
  const handleListener = useCallback(() => {
    if (workspace?.id) loadData();
    const listener = (ev: MessageEvent<any>) => {
      const { type, payload } = ev.data;

      if (type === 'live_users_update') {
        const liveUsers = payload.map((user: { userId: string }) => user.userId);

        // Ensure `liveUsers` contains at least the current user's ID
        if (!liveUsers.includes(userDetails.id)) {
          liveUsers.push(userDetails.id);
        }

        const activeUsers = liveUsers.map((id: string) => {
          const userData = workspace?.users?.find((user) => user?.id === id) || undefined;
          return {
            name: userData?.name || 'Unknown',
            profile: userData?.profile_photo || null
          };
        });

        setActiveUsers(activeUsers);
      }

      if (type === 'on_save') {
        onSave(payload);
      }
    };

    if (workspace?.users?.length > 0 && !listenerAdded) {
      window.addEventListener('message', listener);
      setListenerAdded(true);
    }

    return () => {
      dispatch(clearDocData());
      window.removeEventListener('message', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    return handleListener();
  }, [handleListener]);

  // for clear data
  const clearData = useCallback(() => {
    dispatch(clearDocData());
  }, [dispatch]);

  // for handle cancel icon
  const onCancel = useCallback(() => {
    if (mobile) {
      history.goBack();
    } else if (folderId) {
      history.push(`/projects/details/${params?.id}/files?folder=${folderId}`);
    } else {
      history.push(`/projects/details/${params?.id}/files`);
    }
    clearData();
  }, [clearData, folderId, history, mobile, params?.id]);

  // for open share model
  const openShareModel = useCallback(() => {
    setShareModalOpen(true);
  }, []);

  // for close share model
  const onCloseShareModal = () => {
    setShareModalOpen(false);
  };

  // for fave document
  const onSave = useCallback(
    async (newContent: string) => {
      try {
        setLoading(true);

        let response;
        if (!isEmpty(docData?.id)) {
          const payload = {
            Content: newContent,
            Name: docData?.name
          };
          response = await dispatch(updateDocumentData(docData?.id, payload));
          if (response) dispatch(setSuccessMessage('Document Updated!'));
        } else {
          const payload = {
            Content: docData?.content,
            Name: docData?.name,
            ProjectId: params?.id,
            UploadFileType: FILE_TYPES.DOCS,
            ParentId: folderId ? folderId : null
          };
          response = await dispatch(createNewDoc(payload));
          if (response) dispatch(setSuccessMessage('Document Saved!'));
        }
        if (response) {
          onCancel();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, params?.id, docData, folderId, onCancel]
  );

  // for delete file
  const deleteFile = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setDeleteLoading(true);
        const payload = {
          IsArchived: true
        };
        const result = await dispatch(updateFileData(docData?.id, payload));
        setDeleteModelOpen(false);
        if (result) {
          // history.goBack();
          if (folderId) {
            history.push(`/projects/details/${params?.id}/files?folder=${folderId}`);
          } else {
            history.push(`/projects/details/${params?.id}/files`);
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatch, docData?.id, folderId, history, params?.id]
  );

  // for open rename model
  const openRenameModal = async () => {
    const result = (await dispatch(getFileDetailsById(docData.id))) as ProjectFilesInterface;
    setCurrentFileItem(result);
    setIsFolderModalOpen(true);
  };

  // for close rename model
  const closeRenameModal = async () => {
    setIsFolderModalOpen(false);
  };

  // for update document name
  const updateDocName = useCallback(
    (name: string) => {
      const updatedData = { ...docData, name };
      dispatch(setDocData(updatedData));
    },
    [dispatch, docData]
  );

  // handle full width toggle
  const handleToggle = useCallback(() => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    const newWidth = newValue ? '100%' : '944px';
    iframeRef.current?.contentWindow?.postMessage(
      { type: 'update-editor-width', payload: newWidth },
      `${process.env.REACT_APP_BLOCKSUITE_URL}`
    );
  }, [isChecked]);

  return (
    <>
      <AppLayout isNewDocPage={true}>
        <DocumentBlock isEdge={docData.isEdge}>
          <DocumentHeader>
            <Leftdiv>
              <IconPoint onClick={onCancel}>
                <SVGIcon
                  name='report-left-arrow-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className='arrow-icon'
                />
              </IconPoint>
              <DocumentForm>{docData?.name}</DocumentForm>
              {(docData?.id && !docData?.isBlocksuite) || (
                <Dropdown
                  activeClassName='active'
                  isMinWidth={198}
                  isRemoveMaxHeight={true}
                  content={
                    <Icon>
                      <SVGIcon
                        className='svgicon'
                        name='three-dots-horizontal-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      />
                    </Icon>
                  }
                  trigger='click'>
                  {!docData.isEdge && (
                    <>
                      <DropdownItem
                        key={1}
                        label='Full width'
                        iconName='full-width-icon'
                        iconSize={16}
                        iconTone='stroke'
                        isSlider={true}
                        onClick={handleToggle}
                        isChecked={isChecked}
                      />
                      <Divider />
                    </>
                  )}
                  <DropdownItem
                    key={1}
                    label='Rename'
                    iconName='edit-icon-16x16'
                    iconSize={16}
                    onClick={openRenameModal}
                    iconTone='stroke'
                  />
                  {!docData?.isEdge && (
                    <>
                      <DropdownItem
                        key={2}
                        label='Import'
                        iconName='import-icon'
                        iconSize={16}
                        onClick={handleImportClick}
                        iconTone='stroke'
                      />
                      <Dropdown
                        isMinWidth={166}
                        trigger='hover'
                        content={
                          <DropDiv>
                            <DropdownItem
                              label='Export'
                              iconName='export-icon'
                              key={3}
                              iconTone='stroke'
                              isSuffix={<SVGIcon name='right-arrows-icon' width='16' height='16' viewBox='0 0 16 16' />}
                              justifyAlign='spaceBetween'
                            />
                          </DropDiv>
                        }>
                        <ExportSubMenu editorIframe={iframeRef} />
                      </Dropdown>
                    </>
                  )}
                  <DropdownItem
                    key={4}
                    label='View version history'
                    iconName='history-icon'
                    iconSize={16}
                    onClick={openVersionHistoryModel}
                  />
                  <DropdownItem key={5} label='Save as Template' iconName='save-icon' iconSize={16} iconTone='stroke' />
                  <DropdownItem
                    key={6}
                    label='Delete'
                    iconName='more-menu-delete-icon'
                    iconSize={16}
                    onClick={openDeleteModel}
                    tone='critical'
                  />
                </Dropdown>
              )}
            </Leftdiv>
            {docData?.id && !docData?.isBlocksuite && (
              <DocumentButton>
                <Button title='Cancel' onClick={onCancel} secondary={true} type='button' />
                <Button onClick={handleSave} title={docData?.id ? 'Update' : 'Save'} isLoading={loading} />
              </DocumentButton>
            )}
            {(docData?.id && !docData?.isBlocksuite) || (
              <Buttondiv>
                <AvatarDiv>
                  {activeUsers.map((user) => (
                    <Rightdiv key={getUniqueId()}>
                      <li>
                        <Avatar
                          imgSrc={user?.profile ? user?.profile : ''}
                          name={user.name}
                          size={20}
                          isMilestoneBorder
                        />
                      </li>
                    </Rightdiv>
                  ))}
                </AvatarDiv>

                <Button title='Share' secondary={true} className='button' modelbtn={true} onClick={openShareModel} />
              </Buttondiv>
            )}
          </DocumentHeader>
          {!loading && !isEmpty(userDetails?.id) && !isEmpty(workspace?.id) && !isEmpty(firebaseCustomToken) && (
            <div className='editor' style={{ padding: docData.isEdge ? 0 : '0 8px' }}>
              <iframe
                ref={iframeRef}
                src={`${!docData?.isBlocksuite ? oldDocUrl : docUrl}${
                  docData?.id && !docData?.isBlocksuite ? '/editorjs' : ''
                }?docId=${params?.docid}&firebase_token=${firebaseCustomToken}&workspace_id=${workspace.id}&theme=${
                  isDarkMode ? 'dark' : 'light'
                }&userId=${userDetails.id}&userName=${userDetails.given_name}&isEdge=${docData.isEdge}`}
                style={{ height: 'calc(100dvh - 57px)' }}
                width={'100%'}
                title='teamcamp doc'
                className='docs-section white-board'
                allow='clipboard-read; clipboard-write'
                onLoad={() =>
                  iframeRef.current?.contentWindow?.postMessage(
                    { type: 'load_doc', payload: docData.content },
                    docData?.isBlocksuite ? `${REACT_APP_BLOCKSUITE_URL}` : oldDocUrl
                  )
                }
              />
            </div>
          )}
        </DocumentBlock>
        <ModalCustom width={400} open={isImportModalOpen} onClose={handleImportModalClose}>
          <Importmodal onClose={handleImportModalClose} editorIframe={iframeRef} />
        </ModalCustom>
        <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={334}>
          <Deletemodal
            onClose={closeDeleteModel}
            onOk={deleteFile}
            loading={deleteLoading}
            modaltitle={`Delete Folder`}
            description={`Are you sure you want to delete this Document?`}
          />
        </ModalCustom>
        <ModalCustom open={shareModalOpen} onClose={onCloseShareModal} width={462}>
          <SharedocModal
            onClickCancel={onCloseShareModal}
            editorIframe={iframeRef}
            isEdge={docData?.isEdge || false}
            docTitle={docData.name}
            docId={docData.id || ''}
          />
        </ModalCustom>
        <ModalCustom open={isFolderModalOpen} onClose={closeRenameModal} width={462}>
          <FilesModal
            onClose={closeRenameModal}
            itemData={currentFileItem}
            updateDocName={updateDocName}
            modalData={LINK_FILE_DATA.FOLDER}
          />
        </ModalCustom>
        <ModalCustom open={isVersionHistoryModelOpen} onClose={closeVersionHistoryModel} width={770} isMaxWidth={true}>
          <VersionHistoryModal
            mainIframe={iframeRef}
            docUrl={docUrl}
            docData={docData}
            onClose={closeVersionHistoryModel}
          />
        </ModalCustom>
      </AppLayout>
    </>
  );
};

export default CreateNewDoc;
