import styled from 'styled-components';
export const ColorSquaresWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const ColorSquareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorSquare = styled.div<{ color: string; isActive: boolean }>`
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isActive ? '#40a9ff' : '#d9d9d9')};
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const ColorSquareLabel = styled.div`
  font-size: 14px;
  color: #4b5563;
  margin-top: 8px;
  text-align: center;
  line-height: 1.4;
  font-weight: 500;
`;

export const Square = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 32px;
    width: 16px;
    height: 16px;
    background: white;
    transform: rotate(45deg);
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.05);
  }
`;
