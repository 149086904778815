import React, { useEffect, useState } from 'react';
import ColorPicker from '../ColorPicker/ColorPicker';
import { ColorSquare, ColorSquaresWrapper, PopoverWrapper } from './styles';
import { Bottomdiv, Logo, Mode, Square } from '../../pages/settings/brandDomain/styles';

interface BrandColorsProps {
  lightModeColor: string;
  darkModeColor: string;
  onChange: (field: string, hex: string) => void;
}

const BrandColors: React.FC<BrandColorsProps> = ({ lightModeColor, darkModeColor, onChange }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [activeColorPicker, setActiveColorPicker] = useState<'light' | 'dark' | null>(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handleSquareClick = (mode: 'light' | 'dark', event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.currentTarget;
    const squareRect = target.getBoundingClientRect();
    const wrapperRect = wrapperRef.current?.getBoundingClientRect();

    if (wrapperRect) {
      const top = squareRect.bottom - wrapperRect.top;
      const left = squareRect.left - wrapperRect.left;

      setPopoverPosition({ top, left });
      setActiveColorPicker(mode);
    }
  };

  const handleColorChange = (color: string) => {
    if (activeColorPicker === 'light') {
      onChange('primaryColor', color);
    } else if (activeColorPicker === 'dark') {
      onChange('primaryDarkColor', color);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.color-picker-popover')) {
      setActiveColorPicker(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <ColorSquaresWrapper>
        <Square>
          <Logo>
            <ColorSquare
              color={lightModeColor}
              onClick={(e) => handleSquareClick('light', e)}
              isActive={activeColorPicker === 'light'}
            />
          </Logo>
          <Bottomdiv>
            <p>Color</p>
            <Mode>(Light Mode)</Mode>
          </Bottomdiv>
        </Square>
        <Square>
          <Logo>
            <ColorSquare
              color={darkModeColor}
              onClick={(e) => handleSquareClick('dark', e)}
              isActive={activeColorPicker === 'dark'}
            />
          </Logo>
          <Bottomdiv>
            <p>Color</p>
            <Mode>(Dark Mode)</Mode>
          </Bottomdiv>
        </Square>

        {activeColorPicker && (
          <PopoverWrapper
            className='color-picker-popover'
            style={{
              top: `${popoverPosition.top}px`,
              left: `${popoverPosition.left}px`
            }}>
            <ColorPicker
              value={activeColorPicker === 'light' ? lightModeColor : darkModeColor}
              onChange={handleColorChange}
            />
          </PopoverWrapper>
        )}
      </ColorSquaresWrapper>
    </div>
  );
};

export default BrandColors;
