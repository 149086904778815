import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  isborderredius?: boolean;
  ispayment?: boolean;
  isproject?: boolean;
  isreportinvoice?: boolean;
  istasks?: boolean;
  isteamtimeline?: boolean;
  isLoading?: boolean;
  isProjectDropdown?: boolean;
}

const Table = styled.table<Props>`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  td {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 10px 12px;
    ${Typography.body_md_medium}
    color: var(--text-primary);
    ${(props) =>
      props.istasks &&
      css`
        white-space: nowrap;
      `}
    ${(props) =>
      props.isreportinvoice &&
      css`
        white-space: nowrap;
      `}
  }
  th {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 10px 12px;
    ${Typography.body_md_medium}
    text-transform: uppercase;
    color: var(--text-secondary);
    background-color: var(--background-tertiary);
    border-radius: 0;
    :last-child {
      border-radius: 0 8px 0 0;
    }
    :first-child {
      border-radius: 8px 0 0 0;
    }
  }
  tr td:first-child {
    ${(props) =>
      props.ispayment &&
      css`
        width: 100px;
      `}
    ${(props) =>
      props.isproject &&
      css`
        width: 146px;
        cursor: pointer;
      `}
    ${(props) =>
      props.isreportinvoice &&
      css`
        width: 76px;
      `}
  }
  tr td:last-child {
    ${(props) =>
      (props.ispayment || props.isreportinvoice) &&
      css`
        text-align: right;
        width: 146px;
      `}
  }
  tr th:last-child {
    ${(props) =>
      (props.ispayment || props.isreportinvoice) &&
      css`
        text-align: right;
      `}
  }
  ${(props) =>
    props.isreportinvoice &&
    css`
      th:nth-last-child(2),
      td:nth-last-child(2) {
        text-align: right;
      }
    `}
  tr {
    .paid {
      color: var(--accent-success);
    }
    .overDue {
      color: var(--accent-error);
    }
    .pending {
      color: var(--accent-caution);
    }
    .draft {
      color: var(--text-secondary);
    }
    .partiallyPaid {
      color: var(--accent-secondary);
    }
  }
  .task-name {
    white-space: normal;
  }
  .sub-row {
    color: var(--text-secondary);
  }
  .leave-link {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
  }
  .join-link {
    ${Typography.body_md_medium}
    color: var(--brand-primary);
  }
  .first-column {
    width: 388px;
  }
  .second-column {
    width: 170px;
    @media (max-width: 1024px) {
      width: 190px;
    }
  }
  .third-column {
    width: 170px;
    @media (max-width: 1024px) {
      width: 150px;
    }
  }
  .forth-column {
    width: 74px;
  }
  @media (max-width: 449px) {
    ${(props) =>
      props.ispayment &&
      css`
        white-space: nowrap;
      `}
    tr td:last-child {
      ${(props) =>
        props.ispayment &&
        css`
          text-align: left;
          width: 100%;
        `}
    }
  }
`;

const Bottomdata = styled.div<Props>`
  height: 100%;
  ${(props: Props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;

const TaskPending = styled.div`
  color: var(--accent-error);
  width: 146px;
  ${Typography.body_md_medium}
`;

const TaskDone = styled.p`
  color: var(--accent-success);
  width: 146px;
  ${Typography.body_md_medium}
  margin: 0;
`;

const CutstomItemDiv = styled.div`
  overflow: auto;
  scrollbar-width: none;
`;
export { Table, Bottomdata, TaskDone, TaskPending, CutstomItemDiv };
